import React, { useEffect, useRef } from 'react';
import { Accordion, AccordionItem, Avatar, Breadcrumb, BreadcrumbItem, Header, HeaderOptionItem, HeaderProps, NavigationProps, Panel } from '@appkit4/react-components';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import './gettingStarted.scss';
import { addSideAnchorClass, formatUrl, removeHighlight } from '@utils/common';

export const headerTitle = () => "Appkit";
export const headerContent = () => <HeaderOptionItem iconName="search-outline" label="Search"></HeaderOptionItem>;
export const headerUser = () => (<Avatar label="CD" role="button" disabled={false}></Avatar>);
export const navigationUser = () => <Avatar className="keyboard-focus" label="VR" disabled={false} role="button"></Avatar>;

let anchorArray = [
    {
        value: 'Manual review',
        selected: true,
        linkName: 'manualReview'
    },
    {
        value: "Assigning categories by role",
        linkName: "assigningCategoriesByRole"
    }
];;
const ManualReviewPage = React.forwardRef<HTMLElement>((props, ref) => {
    const navigate = useNavigate();
    const items = [
        { name: 'ARIA', role: 'Developer' },
        { name: 'Color', role: 'Designer' },
        { name: 'Structure', role: 'Designer' },
        { name: 'Forms', role: 'Designer' },
        { name: 'Keyboard', role: 'Designer or PO' },
        { name: 'Language', role: 'Developer' },
        { name: 'Name/Role/Value', role: 'Developer' },
        { name: 'Page zoom', role: 'PM/PO or Designer' },
        { name: 'Semantics', role: 'Developer' },
        { name: 'Sensory and visual cues', role: 'PM/PO' },
        { name: 'Tables', role: 'Developer' },
        { name: 'Text alternatives', role: 'PM/PO' },
        { name: 'Time and media', role: 'PM/PO' }
      ];
    const anchorRef = useRef(null);
    const faqRef = useRef(null);
    const itemListRef = React.useRef<{ name: string, top: number, target: HTMLElement }[]>([]);
    const [anchorList, setAnchorList] = React.useState(anchorArray.map((item, index) => {
        return { ...item, selected: index === 0 ? true : false };
    }));
    const pageContent = React.useRef<HTMLDivElement>(null);
    const [collapsedVal, setCollapsedVal] = React.useState(false);
    const [showAnchors, setShowAnchors] = React.useState(false);
    const [anchorLeft, setAnchorLeft] = React.useState(0);
    const anchorEvent = (fragment?: string) => {
        let str = (fragment ? formatUrl(decodeURI(fragment)) : '');
        let indexStr = anchorArray.findIndex(item => formatUrl(item.linkName) === str);
        if (str === '') indexStr = 0;
        removeHighlight(anchorRef, indexStr);
        const elementTop = fragment ? (document.getElementById(str)?.offsetTop || 0) : 0;
        setTimeout(function () {
            window.scrollTo({
                top: elementTop - 64
            });
        }, 0);
        setAnchorList(anchorArray.map((item, index) => {
            if (formatUrl(item.linkName?.toLowerCase()) === str?.toLowerCase())
                return { ...item, selected: true };
            else if (!fragment && index === indexStr)
                return { ...item, selected: true };
            else return { ...item, selected: false }
        }));
        if ((!fragment && indexStr === 0) ||
            (fragment && indexStr === -1)) (anchorRef.current! as HTMLElement).querySelectorAll(`[id^=anchor-]`)[0].classList.add("selected");
        if (indexStr === -1)
            setTimeout(function () {
                window.scrollTo({
                    top: - 64
                });
            }, 0);
    }

    const hashChangeEvent = () => {
        anchorEvent(window.location.hash.slice(1));
    }

    const setAnchorsEvent = () => {
        const pageContentElement = (pageContent.current as HTMLElement);
        setAnchorLeft(pageContentElement.getBoundingClientRect().left + pageContentElement.getBoundingClientRect().width + 32);
    }

    const isInViewport = (element: HTMLElement) => {
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
        );
    }

    const scrollEvent = () => {
        setTimeout(() => {
            const itemList = itemListRef.current;
            let scrollTop = Math.max(document.documentElement.scrollTop, document.body.scrollTop) + 28;
            for (let index = 0; index < itemList.length; ++index) {
                const trigger = (anchorRef.current! as HTMLElement).querySelector(`[id=anchor-${itemList[index]?.name.toLowerCase()}]`) as HTMLElement;
                if (itemList[index + 1] && scrollTop >= itemList[index].top - 128 && scrollTop <= itemList[index + 1].top - 128) {
                    addSideAnchorClass(anchorRef, trigger);
                    if (!itemList[index + 2] && (window.innerHeight + Math.round(window.scrollY)) >= document.body.offsetHeight && isInViewport(itemList[index + 1].target))
                        addSideAnchorClass(anchorRef, (anchorRef.current! as HTMLElement).querySelector(`[id=anchor-${itemList[index + 1]?.name.toLowerCase()}]`) as HTMLElement);
                    break;
                } else if (index === itemList.length - 1 && scrollTop >= itemList[index].top - 64) {
                    addSideAnchorClass(anchorRef, trigger);
                    break;
                }
            }
        }, 300);
    }

    React.useEffect(() => {
        setTimeout(() => {
            if (pageContent.current) {
                removeHighlight(anchorRef);
                setAnchorsEvent();
                setShowAnchors(true);
            }
            hashChangeEvent();
        }, 100);
        window.addEventListener('hashchange', hashChangeEvent);
        window.addEventListener('resize', setAnchorsEvent);
        window.addEventListener('scroll', scrollEvent);
        window.addEventListener('keyup', keyupEvent);
        updateItemListRef();
        return () => {
            window.removeEventListener('hashchange', hashChangeEvent);
            window.removeEventListener('scroll', scrollEvent);
            window.removeEventListener('resize', setAnchorsEvent);
        }
    }, [])

    const keyupEvent = (event: KeyboardEvent) => {
        if (event.keyCode === 9) {
            const accordionList = (faqRef.current! as HTMLElement).querySelectorAll('.ap-accordion-toggle');
            if(!accordionList) return;
            if (Array.from(accordionList).indexOf(document.activeElement!) === accordionList.length - 1)
                addSideAnchorClass(anchorRef, (anchorRef.current! as HTMLElement).querySelector(`[id=anchor-${itemListRef.current[itemListRef.current.length - 1]?.name.toLowerCase()}]`) as HTMLElement);
            if (document.activeElement?.textContent === 'Getting started')
                addSideAnchorClass(anchorRef, (anchorRef.current! as HTMLElement).querySelector(`[id=anchor-${itemListRef.current[0]?.name.toLowerCase()}]`) as HTMLElement);
        }
    }

    const updateItemListRef = () => {
        setTimeout(() => {
            let intersectItem = (pageContent.current! as HTMLElement).querySelectorAll('.anchor-target');
            let tempList = [];
            for (let i = 0; i < intersectItem.length; ++i) {
                const item = (intersectItem[i] as HTMLElement);
                tempList.push({ name: item.id, top: item.offsetTop, target: item });
            }
            itemListRef.current = tempList;
            setAnchorsEvent();
        }, 300);
    }

    const onAnchorClick = (value?: string) => {
        anchorEvent(value);
    }

    const onClickStart = () => {
        navigate('/getting-started/tool-overview');
    }

    const onKeyDown = (event: React.KeyboardEvent<HTMLElement>, value?: string) => {
        const { key } = event;
        if (key === 'Enter') {
            onAnchorClick?.(value)
        }
    }

    return (
        <div className="ap-page-get-started">
            <div className="pattern-content faq-content">
                <div className={classNames("ap-page has-anchor", {
                    'faq-page-collapse': collapsedVal
                })}>
                    {/* <PageAnchors showAnchors={showAnchors} className="anchor-list" ref={anchorRef} anchorList={anchorList} onAnchorClick={onAnchorClick}></PageAnchors> */}

      <div className="ap-page-content has-anchor" id="pageContent" ref={pageContent}>
    <Breadcrumb>
        <BreadcrumbItem>
            <span onClick={onClickStart} tabIndex={0}>Getting started</span>
        </BreadcrumbItem>
        <BreadcrumbItem>
            <span>Manual review</span>
        </BreadcrumbItem>
    </Breadcrumb>
        <div className="ap-component-wrapper no-top-bottom ap-mt-spacing-7">
          <div className="ap-component-name anchor-target" id="manualreview">
          Manual review
          </div>
          <div className="ap-page-section-desc">
          The manual review is conducted by your team members using various tools and techniques to identify accessibility issues. It focuses on core user flows and involves collaboration among product owners/managers, designers, developers, and accessibility experts.  
          </div>
            <Panel bordered={true} className='ap-mt-spacing-4 manual-content'>
                <div className="ap-panel-content step-content manual-content">
                The Accessibility Checker offers a self-guided manual review that allows all team members to review categories or questions they are comfortable with answering. Review answers are saved automatically and can be completed over time. 
                </div>
            </Panel>
            <div className='category-card-panel-wrapper ap-mt-spacing-7'>
                <div className="ap-component-name anchor-target" id="assigningcategoriesbyrole">Assigning categories by role</div>
                    <div>Here's a general example of how teams can assign categories by role: </div>
                        <div className='category-card-panel'>
                            {items.map((item: any, index: number) => (
                                <div className='category-card' key={index}>
                                <div className='ap-mb-spacing-2'>{item.name}</div>
                                <div>{item.role}</div>
                                </div>
                            ))}
                        </div>
            </div>
            <div className="ap-page-section-desc ap-mt-spacing-4 ap-mb-spacing-7 notification-panel">
                <span className="Appkit4-icon icon-information-outline"></span>
                <div>It is recommended to open the manual review in a separate window or monitor while having the application open for easy reference. Answers are saved automatically and can be completed over time. </div>
            </div>
        </div>
      </div>

    <nav ref={anchorRef} className={classNames('ap-pattern-page-anchor', "anchor-list")} style={{ left: `${anchorLeft}px` }}>
        <ul>
            {
                anchorList?.map(
                    (anchor: any, index: number) => {
                        const hrefValue = window.location.href.indexOf("#") > -1 ? window.location.href.substring(0, window.location.href.indexOf("#")) : window.location.href;
                        const hrefProp = true ? { href: encodeURI(hrefValue + '#' + formatUrl(anchor.linkName!)) } : null;
                        return <li key={index} onClick={(event) => onAnchorClick?.(anchor.linkName)} onKeyDown={(event) => onKeyDown(event, anchor.linkName)}>
                            <a tabIndex={0} role="link" aria-current={anchor.selected ? 'page' : undefined} {...hrefProp} id={'anchor-' + formatUrl(anchor.linkName!)}
                                className={classNames("anchor-trigger", { 'selected': anchor.selected })}>
                                {anchor.externalLink && <span className={anchor.externalLinkIcon || "Appkit4-icon icon-open-in-new-outline"}></span>}
                                {anchor.value}
                            </a>
                        </li>
                    }
                )
            }
        </ul>
    </nav>
        </div>
    </div>
</div>
    )
})

export default ManualReviewPage
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import Entry from '@layout/Entry';
import ErrorPage from '@layout/ErrorPage';
import error from '@assets/icons/ComingSoon.svg';
import Login from '@layout/Login/index';
import { createBrowserHistory } from 'history';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
let isDesired = true;
const currentUrl = new URL(window.location.href);
const env = process.env.REACT_APP_env;
if (window.location.href?.includes('/v2')) {
    const history = createBrowserHistory();
    history.push(window.location.href.replace('/v2', ''));
    window.location.reload();
}
if (env === 'local') 
    isDesired = true;
else {
    const desiredDomain = new URL(process.env.REACT_APP_app_redirect_url||'').hostname || '';//process.env.REACT_APP_app_redirect_url
    if (window.location.hostname !== desiredDomain) {
        currentUrl.hostname = desiredDomain;
        currentUrl.port = '';
        isDesired = false;
    }
    else
        isDesired = true;
}

const onClickRedirect = () => {
    const history = createBrowserHistory();
    history.push(currentUrl.toString());
}
const Page = () => (
    isDesired? <Login></Login> : <ErrorPage errorImage={error} pageTitle="The Accessibility Checker has a new home!" onClickRedirect={onClickRedirect}></ErrorPage>
);
root.render(<Page/>);

reportWebVitals();

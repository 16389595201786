import React, { useEffect, useRef, useState } from 'react';
import { Button, HeaderProps, NavigationProps, Tab, Tabs } from '@appkit4/react-components';
import classNames from 'classnames';
import './index.scss';
import { getTerritory } from '@services/resource.service';
import LoadingComponent from '@components/Loading';

export interface FAQProps {
    headerProps?: HeaderProps;
    navProps?: NavigationProps;
}

const LicensingPage = React.forwardRef<HTMLElement, FAQProps>((props: FAQProps, ref) => {
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [isLoadingRegion, setIsLoadingRegion] = useState(false);
    const [countries, setCountries] = useState<any>([]);
    const countriesRef = useRef<any>([]);
    const onActiveTabChange = (index: number) => {
        setActiveTabIndex(index);
    }
    const links = [
        { href: "https://pwc.sharepoint.com/sites/GBL-xLoS-Global_Accessibility/SitePages/Home.aspx?csf=1&web=1&e=h7lfb8&CID=b100756e-fd5d-424f-952b-abd2016e460f", text: "Global Digital Accessibility at PwC", className: "no-margin-top", showIcon: true },
        { href: "https://pwc.sharepoint.com/sites/GBL-IFS-accessibility-inclusive-design/SitePages/Accessible.-Human-led.-Tech-Powered.aspx", text: "US Accessibility and Inclusive Design Site", showIcon: true },
        { href: "https://pwc.sharepoint.com/sites/GBL-IFS-accessibility-inclusive-design/SitePages/Recourses.aspx", text: "Resources - Role-based Accessibility Checklists", showIcon: true },
        { href: "https://pwc.mediaspace.kaltura.com/channel/P%2526T%2BDigital%2BAccessibility%2Band%2BInclusive%2BDesign%2BTraining/322850392", text: "US Accessibility and Inclusive Design MediaSpace Training Videos", showIcon: false }
    ];

    const onClickLink = (useFor: string) => {
        if (useFor === 'internal')
            window.open('https://pwceur.sharepoint.com/sites/PwcSolutions/SitePages/Asset-Details.aspx?Ref=191', '_blank');
        else
            window.open(' https://pwceur.sharepoint.com/sites/PwcSolutions/SitePages/Asset-Details.aspx?Ref=192', '_blank');
    }

    const updateRowIndex = () => {
        setCountries(countriesRef.current.map((country: any, index: number) => ({
            value: country.territoryName,
            flagUrl: country.flagImg,
            rowIndex: window.innerWidth < 1080 ? (index % 12) + 1 : (index % 8) + 1,
        })));
    }

    useEffect(() => {
        setIsLoadingRegion(true);
        getTerritory().subscribe((res: any) => {
            setIsLoadingRegion(false);
            countriesRef.current = res?.[0].contents;
            const countriesInfo = res?.[0].contents
                .map((country: any, index: number) => ({
                    value: country.territoryName,
                    flagUrl: country.flagImg,
                    rowIndex: window.innerWidth < 1080 ? (index % 12) + 1 : (index % 8) + 1,
                }));
            setCountries(countriesInfo);
        });
        window.addEventListener('resize', updateRowIndex);
        return () => {
            window.removeEventListener('resize', updateRowIndex);
        }
    }, [])

    return (
        <>
            <div className="ap-page ap-page-content-for-licensing" tabIndex={-1}>
                <div className="ap-page-content">
                    <div className="ap-component-wrapper without-margin-bottom">
                        <div className="ap-page-list">
                            <div className="ap-page-section no-margin-top image-wrapper">
                                <div className="ap-page-section-img title-image"><h1>Licensing</h1></div>
                                {/* <div className="ap-page-section-img title-image"> */}
                                {/* <div className="img-details"> */}
                                {/* <img src="app/images/figma/classic.svg" alt=""><br/> */}
                                {/* <div className="ap-page-section-desc img-desc">Classic auto-layout</div> */}
                                {/* </div> */}
                                {/* </div> */}
                            </div>

                            <div className="ap-page-section-desc with-margin-top">
                                Thank you for your interest in licensing Accessibility Checker.
                            </div>
                            <div className="ap-page-section-desc with-margin-top">The Accessibility Checker can be licensed for two purposes: </div>
                            <div className="ap-page-list-section">
                                <ul>
                                    <li><span className='bold'>Internal product use</span>: Incorporate the tool into your internal workflows to enhance accessibility across your products.</li>
                                    <li><span className='bold'>Client use</span>: Leverage the tool to deliver accessible and inclusive solutions to your clients.</li>
                                </ul>
                            </div>
                            <div className="ap-page-list-section">
                                {/* <h4 className="ap-page-list-subtitle">1. Core library</h4> */}
                                <div className="ap-page-list-content">Each licensing option has its own process, and fees may apply. Review the steps below for the licensing type needed and get started with ease!</div>
                            </div>
                        </div>
                        {/* <div className="ap-page-card-container">
                <a className="ap-page-card clickable" target="_blank"
                   href="https://pwc-my.sharepoint.com/:u:/p/luke_van_zyl/Ec0AJVFPPOJOkczTvMUQOvoBNrm5JIMYiDkWunQDoUtfZQ?e=VtHlf0">
                    <div className="card-top">
                        <div className="card-top-left">Get the Figma Library kit</div>
                        <div className="card-bottom-left">Download the Figma file</div>
                    </div>
                    <span className="ap-page-card-svg card-right Appkit4-icon icon-download-cloud-outline"></span>
                </a>
            </div> */}
                    </div>

                    <div className="ap-component-wrapper without-margin-bottom">
                        <h2 className="ap-component-name anchor-target" id="resources">
                            Licensing process
                        </h2>
                        <div className="ap-page-card" id="useLibrary">

                            <div className="tabsContentPart">
                                <div className="tabsContainer">
                                    <Tabs type='filled' activeIndex={activeTabIndex} onTabChange={onActiveTabChange}>
                                        <Tab label={"For internal use"} value={'internal'} key={`0-internal`}></Tab>
                                        <Tab label={"For client use"} value={'external'} key={`1-external`}></Tab>
                                    </Tabs>
                                </div>
                                <div className="tabContent">
                                    {
                                        activeTabIndex === 0 ? (
                                            <>
                                                <div className="ap-page-list within-page-card">
                                                    <div className="ap-page-list-section without-margin-top">
                                                        <h3 className="ap-page-list-subtitle">Review licensed territories</h3>
                                                        <div className="ap-page-list-content ap-mb-spacing-5">1. Check the <span className='bold'>Licensed territories</span> section below to see if your territory is already licensed.</div>
                                                        <h3 className="ap-page-list-subtitle">Access PwC Solutions</h3>
                                                        <div className="ap-page-list-content ap-mb-spacing-5">2. Click on the button below of PwC Solutions for internal use licensing.</div>
                                                        <Button kind='primary' className="link-btn" onClick={() => onClickLink('internal')} ariaLabel="Go to PwC solutions (for internal use). Opens in a new tab."><span className="Appkit4-icon icon-arrow-increase-small-outline"></span>
                                                            <span>Go to PwC solutions (for internal use)</span></Button>
                                                    </div>
                                                    <div className="ap-page-list-section without-margin-top">
                                                        <h3 className="ap-page-list-subtitle">Review process</h3>
                                                        <div className="ap-page-list-content">3. Ensure that the territory partner sponsor is involved during the review process.</div>
                                                        <div className="ap-page-list-content">4. Review the TRF (Risk Assessment) and addendum with your territory's risk and quality team. </div>
                                                    </div>
                                                    <div className="ap-page-list-section">
                                                        <h3 className="ap-page-list-subtitle">Approval</h3>
                                                        <div className="ap-page-list-content">5. Once the review is complete, have the approving partner press the <span className='bold'>License IP</span> button. </div>
                                                        <div className="ap-page-list-content">6. Fill out the form to complete and approve the license for your territory. </div>
                                                    </div>
                                                    {/* <div className="ap-page-section-desc ap-mt-spacing-3 notification-panel">
                                                        <span className="Appkit4-icon icon-information-outline"></span>
                                                        <div>The annual licensing cost for internal use is covered by the Global Diversity & Inclusion Team. </div>
                                                    </div> */}
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="ap-page-list within-page-card">
                                                    <div className="ap-page-list-section without-margin-top">
                                                        <h3 className="ap-page-list-subtitle">Access PwC Solutions</h3>
                                                        <div className="ap-page-list-content ap-mb-spacing-5">1. Click on the button below of PwC Solutions for client-use licensing.</div>
                                                        <Button kind='primary' className="link-btn" onClick={() => onClickLink('client')} ariaLabel="Go to PwC solutions (for client use). Opens in a new tab."><span className="Appkit4-icon icon-arrow-increase-small-outline"></span>
                                                            <span>Go to PwC solutions (for client use)</span></Button>
                                                    </div>
                                                    <div className="ap-page-list-section without-margin-top">
                                                        <h3 className="ap-page-list-subtitle">Review process</h3>
                                                        <div className="ap-page-list-content">2. Ensure that the partner sponsor is involved during the review process.</div>
                                                        <div className="ap-page-list-content">3. Review the TRF (Risk Assessment) and addendum with your risk and quality team.</div>
                                                    </div>
                                                    <div className="ap-page-list-section">
                                                        <h3 className="ap-page-list-subtitle">Approval</h3>
                                                        <div className="ap-page-list-content">4. Once the review is complete, have the approving partner press the <span className='bold'>License IP</span> button.</div>
                                                        <div className="ap-page-list-content">5. Fill out the form to complete and approve the license.</div>
                                                    </div>
                                                    {/* <div className="ap-page-section-desc ap-mt-spacing-3 notification-panel">
                                                        <span className="Appkit4-icon icon-information-outline"></span>
                                                        <div>The annual licensing cost for internal use is covered by the Global Diversity & Inclusion Team. </div>
                                                    </div> */}
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="ap-component-wrapper without-margin-bottom">
                        <h2 className="ap-component-name anchor-target" id="foundations">
                            Licensed territories
                        </h2>
                        <div className="ap-page-list">

                            <div className="ap-page-section-desc with-margin-bottom">The following territories have signed the licensing agreement for using the Accessibility Checker for internal purposes. If your territory is listed, you're all set—no further action is needed.</div>
                            <div className="ap-page-section no-margin-top with-country">
                                {/* <div className="vertical-line"></div> */}
                                <div className={classNames("ap-page-section-img", { "is-loading": isLoadingRegion })}>
                                    {isLoadingRegion && <LoadingComponent />}
                                    {!isLoadingRegion && <div className="licensing-main-info-country">
                                        {countries.map((country: any, index: number) => (
                                            <div
                                                key={index}
                                                className={classNames("grid-item", { "with-border": countries.length > 8 })}
                                                style={{
                                                    gridRow: country.rowIndex,
                                                    gridTemplateRows: country.length >= 8 ? 'repeat(8, 1fr)' : '',
                                                }}
                                            >
                                                {country.flagUrl && (
                                                    <img src={country.flagUrl} alt={country.value} />
                                                )}
                                                <span className={country.flagUrl ? '' : 'ap-ml-spacing-5'}>
                                                    {country.value}
                                                </span>
                                            </div>
                                        ))}
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ap-component-wrapper without-margin-bottom">
                        <h2 className="ap-component-name anchor-target" id="foundations">
                            Resources
                        </h2>
                        <div className="ap-page-card">
                            {links.map((link, index) => (
                                <div key={index} className={`ap-page-section-item ${link.className || ''}`}>
                                    <a className="ap-link" aria-label={`${link.text}, opens in a new tab.`} target="_blank" href={link.href}>
                                        {link.text}
                                    </a>
                                    {link.showIcon && <span className="Appkit4-icon icon-arrow-increase-small-outline"></span>}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="ap-component-wrapper without-margin-bottom">
                        <h2 className="ap-component-name anchor-target" id="resources">
                            Need help?
                        </h2>
                        <div className="ap-page-card">
                            <div className="ap-page-section-desc">For more questions about licensing, please contact <span className='bold'>Ashley Bixler (ashley.e.bixler@pwc.com)</span>.</div>
                            <div className="ap-page-section-desc desc-padding-top">
                                If you have any questions about accessibility or inclusive design, contact the <a className="ap-link" target="_blank" aria-label="US Accessibility team, opens in a new tab." href="https://forms.office.com/Pages/ResponsePage.aspx?id=oJQyUSA-skGpcG0wvxVG-qJtLN_BonZFrxiPNTnvCsRUNFUzWUwySEdIUjJWN1RXNkhENjYwUjhNNC4u">US Accessibility team.</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
})

export default LicensingPage
export const authenticationTypesData = [
  {
    title: "PwC IdAM or IAM’s federated PwC Identity service",
    desc: 'The application uses PwC’s access management services for authentication.',
    icon: 'icon-shield-half-outline',
    value: 'idamOrIam',
    clicked: false,
  },
  {
    title: 'IAM or other authorization services',
    desc: 'The application uses access management services provided by external companies or third-party providers.',
    icon: 'icon-shield-outline',
    value: 'iamOrOther',
    clicked: false,
  },
  {
    title: 'Authentication type unknown',
    desc: "Unsure which authentication method is used.",
    icon: 'icon-help-question-outline',
    value: 'unsure',
    clicked: false,
  },
];
export const authenticationTypesDataForSettingsPage = [
  {
    title: "PwC IdAM or IAM's federated PwC Identity service",
    desc: 'The application uses PwC’s access management services for authentication.',
    icon: 'icon-shield-half-outline',
    value: 'idamOrIam',
    clicked: false,
  },
  {
    title: 'IAM or other authorization services',
    desc: 'The application uses access management services provided by external companies or third-party providers.',
    icon: 'icon-shield-outline',
    value: 'iamOrOther',
    clicked: false,
  },
];

export const idamOrIamExpandData = [
  {
    label: "Go to the application's admin panel.",
    value: 'goTo',
    links: [],
  },
  {
    label: 'Add the following accounts to the user profiles and assign them <strong>admin permissions</strong>: ',
    value: 'addAccounts',
    links: ['USDIPAccessibility.Assessor@pwc.com', 'USDIPAccessibility.ProdAssessor@pwc.com'],
  },
  {
    label: 'Return to this page and confirm the set up is complete by selecting the checkbox below.',
    value: 'returnConform',
    links: [],
  },
];

import React, { FC, useEffect, useRef, useMemo, useContext, useState } from 'react';
import { Navigation, NavigationItem } from '@appkit4/react-components/navigation';
import { Avatar } from '@appkit4/react-components/avatar';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { ISideType, RPathMappingType } from './types';
import { EntryContext } from '@layout/Entry';
import './sidebar.scss';
import { Button } from '@appkit4/react-components';
import * as jwt from 'jsonwebtoken';
import { decryptData, getCookie, getProductInfoCookie, safelyParseJSON } from '@utils/common';
import { userService } from '@services/user.service';
import { take } from 'rxjs/operators';
import FloatingMenu from '@components/FloatingMenu/FloatingMenu';

const SideBarNavigation: FC<ISideType> = (props) => {
  const { setCollapsed } = useContext(EntryContext);
  const { tabKey, appId, isForAll = false } = props;
  const location = useLocation();
  const [search] = useSearchParams();
  const [isSubscribed, setIsSubscribed] = useState<boolean|null>(null);
  const localStorageProductInfo = useMemo(() => {
    const temp = getProductInfoCookie();
    return temp && safelyParseJSON(temp);
  }, []);

  const productId = useMemo(() => {
    return localStorageProductInfo && localStorageProductInfo.productId;
  }, [localStorageProductInfo]);

  const sideBarRef = useRef<any>();

  const navList: NavigationItem[] = !isForAll? [
    {
      name: 'Dashboard',
      prefixIcon: 'grid-view',
      prefixCollapsedIcon: 'grid-view',
    },
    {
      name: 'Issues',
      prefixIcon: 'alert',
      prefixCollapsedIcon: 'alert',
    },
    {
      name: 'Settings',
      prefixIcon: 'setting',
      prefixCollapsedIcon: 'setting',
    },
  ] : [
    {
      name: 'Welcome',
      prefixIcon: 'home',
      prefixCollapsedIcon: 'home',
    },
    {
      name: 'Getting started',
      prefixIcon: 'compass',
      prefixCollapsedIcon: 'compass',
      suffixIcon: 'down-chevron',
      children: [
        {
          name: 'Tool overview',
          prefixCollapsedIcon: 'grid-view',
        },
        {
            name: 'Licensing',
            prefixCollapsedIcon: 'grid-view',
        }]
    },
    {
      name: 'Help center',
      prefixIcon: 'help-question',
      prefixCollapsedIcon: 'help-question'
    }
  ];
  //<span class="Appkit4-icon icon-setting-outline"></span>
  const navigate = useNavigate();
  const navigationRef = useRef(null);
  const [selectedKey, setSelectedKey] = React.useState('');

  const getRpath = (key: string) => {
    const rPathMapping: RPathMappingType = !isForAll? {
      Dashboard: 'board',
      Issues: 'issues',
      Settings: 'settings',
    } : {
      Welcome: 'welcome',
      GettingStarted: 'getting-started',
      HelpCenter: 'help-center'
    };
    const keyName = navList[Number(key)].name;
    return rPathMapping[keyName];
  };

  useEffect(() => {
    const token = getCookie('idToken')!;
    const decodeds: any = jwt.decode(token);
    if(token)
    userService
      .getSubscription(decodeds?.preferredMail)
      .pipe(take(1))
      .subscribe((res) => {
        setIsSubscribed(res.subscriptionFlag === 1? true : false)
      });
  }, [])

  const subscribeBtnClick = () => {
    setIsSubscribed(null);
    const token = getCookie('idToken')!;
    const decodeds: any = jwt.decode(token);
    userService
      .setSubscription(decodeds?.preferredMail, !isSubscribed? '1' : '0')
      .pipe(take(1))
      .subscribe((res) => {
        setIsSubscribed(!isSubscribed);
      });
  }

  useEffect(() => {
    const pathname = location.pathname;
    if (!isForAll) {
      if (pathname?.includes('/dwrapper/issues') || pathname?.includes('/dwrapper/issuedetail')) {
        setSelectedKey('1');
      } else if (pathname?.includes('/dwrapper/board')) {
        setSelectedKey('0');
      } else if (pathname?.includes('/dwrapper/settings')) {
        setSelectedKey('2');
      }
    } else {
      if (pathname === '/') {
        setSelectedKey('0');
      } else if (pathname?.includes('/getting-started/tool-overview') ||
      pathname?.includes('/getting-started/manual-review') ||
      pathname?.includes('/getting-started/automated-assessment')) {
        setSelectedKey('1-0');
      } else if (pathname?.includes('/getting-started/licensing')) {
        setSelectedKey('1-1');
      } else if (pathname?.includes('/help-center')) {
        setSelectedKey('2');
      }
    }
  }, [location]);

  useEffect(() => {
    const pathname = location.pathname;
    let uri = '';
    if(!isForAll) {
      if (pathname?.includes('/dwrapper/issues')) {
        uri = `/dwrapper/issues?appId=${appId}`;
      } else if (pathname?.includes('/dwrapper/issuedetail')) {
        uri = `/dwrapper/issuedetail?appId=${appId}&id=${search.get('id')}`;
      } else if (pathname?.includes('/dwrapper/settings')) {
        uri = `/dwrapper/settings?appId=${appId}`;
      } else if (pathname?.includes('/dwrapper/issuenotfound')) {
        uri = `/dwrapper/issuenotfound?appId=${appId}`;
      } else if (pathname?.includes('/dwrapper/board')) {
        uri = `/dwrapper/board?appId=${appId}`;
      } else {
        uri = `/dwrapper/${getRpath(selectedKey)}?appId=${appId}`;
      }
    } else {
      uri = pathname;
      if (pathname.includes('/getting-started/licensing') ||
        pathname.includes('/getting-started/tool-overview') || 
        pathname.includes('/getting-started/automated-assessment') || 
        pathname.includes('/getting-started/manual-review')) {
        setTimeout(() => {
          const domItem = document.querySelector('.ap-nav-item.nav-with-child.item-1') as HTMLElement;
          if (domItem && !domItem.classList.contains('nav-item-expanded')) {
            domItem.click();
          }
        }, 200);
      }
    }
    navigate(uri);
  }, []);

  const onClickLogo = (event: React.MouseEvent<HTMLElement>) => {
    console.log('onClickLogo', event);
  };

  const onCollapseEvent = (
    collapsed: boolean,
    event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
  ) => {
    setCollapsed(collapsed);
  };

  const adjustSidebarFooterPosition = () => {
    setTimeout(() => {
      const items = (navigationRef.current! as HTMLElement)?.querySelectorAll('.ap-nav-item');
      if (!items || items?.length === 0) return;
      const lastNavItem = items[items.length - 1];
      const subscribePanel = (navigationRef.current! as HTMLElement).querySelector('.btn-subscribe-panel') as HTMLElement;
      if (lastNavItem && subscribePanel) {
        const lastNavItemRect = lastNavItem.getBoundingClientRect();
        const topPosition = lastNavItemRect.bottom - (location.pathname?.endsWith('/') ? 64 : 0) + 16;
        subscribePanel.style.top = `${topPosition}px`;
      }
    }, 0);
  };

  useEffect(() => {
    if (!isForAll) return;
    adjustSidebarFooterPosition();
  }, [])

  useEffect(() => {
    if (sideBarRef?.current) {
      const navNode = sideBarRef.current;
      const contentTarget = navNode.querySelectorAll('.ap-navigation-content')[0] as HTMLElement;

      contentTarget.setAttribute('role', 'menubar');
      // contentTarget.removeAttribute('tabindex');

      const childTargets = navNode.querySelectorAll('.nav-with-child');
      childTargets.forEach((node: HTMLElement) => {
        node.removeAttribute('role');
        // node.removeAttribute('tabindex');
      });
    }
  }, [sideBarRef]);

  const onClickEvent = (key: string) => {
    setSelectedKey(key);
    navigate(`/dwrapper/${getRpath(key)}?appId=${appId}`, {
      state: {
        productId,
        appId,
      },
    });
  }

  const onClickBottomIcon = () => {
    window.open(
      'https://appkit.pwc.com/appkit4/content/help-center/feedback?tool=accessibility&newissue=true&version=angular',
      '_blank',
    );
  }

  return (
    <div ref={navigationRef} className='ap-navigation-bar'>
      {!isForAll? <FloatingMenu 
      onClickEvent={onClickEvent} 
      onClickBottomIcon={onClickBottomIcon}
      selectedPanel={selectedKey}>
      </FloatingMenu> :
    <Navigation
      ref={sideBarRef}
      width={280}
      navList={navList}
      showTooltip={true}
      hasHeader={false}
      selectedKey={selectedKey}
      defaultOpenKeys={[]}
      className={'sideBar-container'}
      onItemClick={(event: any, item: NavigationItem, key: string) => {
        event.stopPropagation();
        setSelectedKey(key);
        if (isForAll) {
          if (key === '0')
            navigate(`/`);
          else if (key === '1-0')
            navigate(`/getting-started/tool-overview`);
          else if (key === '1-1') {
            navigate(`/getting-started/licensing`);
        } else if (key === '2')
            navigate(`/help-center`);
        } else
          navigate(`/dwrapper/${getRpath(key)}?appId=${appId}`, {
            state: {
              productId,
              appId,
            },
          });
      }}
      hasFooter={false}
      role="menuitem"
      onClickItem={()=>adjustSidebarFooterPosition()}
      onClickCollapseEvent={onCollapseEvent}
      titleTemplate={() => 'Appkit'}
      userTemplate={() => <Avatar className="keyboard-focus" label="VR" disabled={false} role="button"></Avatar>}
    ></Navigation>}
      {!location.pathname.includes('/dwrapper') && <div className="btn-subscribe-panel">
        <div className="separator-line"></div>
        <div className="btn-subscribe-description ap-mb-spacing-5 ap-ml-spacing-4 ap-mr-spacing-4">
          <div className='description-subscribe ap-mb-spacing-6'>
            Subscribe to receive important updates and release notes directly.
          </div>
          {isSubscribed === null ?
            <Button kind='secondary' loading={true} disabled={true} compact>{!isSubscribed ? 'Subscribe' : 'Unsubscribe'}</Button> :
            <Button kind='secondary' onClick={subscribeBtnClick} compact>{!isSubscribed ? 'Subscribe' : 'Unsubscribe'}</Button>
          }
        </div>
        <div>
        </div>
      </div>}
      </div>
  );
};

export default SideBarNavigation;

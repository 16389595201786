import { Observable } from "rxjs";
import { apiService } from "./rxjs.service";

export const getFAQ = (): Observable<any> => {
    return apiService.get(`/resources?type=faq`);
  };

export const getReleaseNotes = (): Observable<any> => {
  return apiService.get(`/resources?type=release_note&order=desc`);
};

export const getTerritory = (): Observable<any> => {
  //getTerritory
  return apiService.get(`/resources?type=territory&order=asc&orderBy=territoryName`);
};
import React, { useState, useEffect, useMemo, useCallback, useContext, useRef } from 'react';
import { Tabs, Tab } from '@appkit4/react-components/tabs';
import { Table, Column } from '@appkit4/react-components/table';
import { Button } from '@appkit4/react-components/button';
import AvatarNew from '@components/AvatarNew';
import { Switch } from '@appkit4/react-components/switch';
import './index.scss';
import { EntryContext } from '@layout/Entry';
import NoMembersPageLogo from '@assets/icons/noMemberPageLogo.svg';
import { productService, userService } from '@services';
import { Subscription, of, zip } from 'rxjs';
import { take, switchMap, concatMap, filter, map } from 'rxjs/operators';
import LoadingComponent from '@components/Loading';
import CommonModal from '@components/modals/CommonModal';
import { Input } from '@appkit4/react-components/field';
import { Search } from '@appkit4/react-components/search';
import SelectedUser from '@components/Home/SelectedUser.component';
import { changeEleToFirstPlace, decryptData, encryptData, getCookie, getProductInfoCookie, safelyParseJSON, setCookie, uuid } from '@utils/common';
import _, { isEqual, set } from 'lodash';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import { Select, Badge } from '@appkit4/react-components';
import { Pagination } from '@appkit4/react-components/pagination';
import { assetService } from '@services/asset.service';
import NoScansSvg from '@assets/icons/no-products.svg';
import NoAccessRequirementsSvg from '@assets/icons/noMemberPageLogo.svg';
import { NoResultsPage } from '@components/Home';
import EditPagesToScanModal from '@components/modals/EditPagesToScanModal';
import EditOtherSettingsModal from '@components/modals/EditOtherSettingsModal';
import EditAccessRequirementsModal from '@components/modals/EditAccessRequirementsModal';
import moment from 'moment';
import { axios } from '@services/axios.service';
import { getNameAbbrev, getRoleValue, capitalizeFirstLetter } from '@utils/common';
import { rolesBtn, rolesArr } from '@components/Home/constant';

const settingsPageTabLabels = [
  { name: 'General', value: 'general' },
  { name: 'Automated scan', value: 'automatedscan' },
];

const SettingsPage = () => {
  const { user, setProductName, setMessageResult } = React.useContext(EntryContext);
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const urlPartSelectPart = [
    { value: '5', label: '5' },
    { value: '10', label: '10' },
    { value: '15', label: '15' },
  ];

  const location = useLocation();
  const [search] = useSearchParams();

  const initTabIndex = () => {
    const tabIndex = location.state?.activeTabIndex;
    if (tabIndex && tabIndex === 1) {
      return 1;
    }
    return 0;
  };

  const [stpTabActiveIndex, setStpTabActiveIndex] = useState(initTabIndex);
  const [originalTeamMembersTableData, setOriginalTeamMembersTableData] = React.useState<any[]>([]);
  const [originalUrlTblData, setOriginalUrlTblData] = React.useState<any[]>([]);
  const [ENSwitchVal, setENSwitchVal] = React.useState(false);
  const [teamMembers, setTeamMembers] = React.useState<any[]>([]);
  const teamMembersRef = useRef<any[]>([]);
  const [statictTeamMembers, setStatictTeamMembers] = React.useState<any[]>([]);
  const [showLoading, setShowLoading] = useState(false);
  const [showManageAccessModal, setShowManageAccessModal] = useState(false);
  const showManageAccessModalRef = useRef(false);
  const [managerAccessUserSearchList, setManagerAccessUserSearchList] = React.useState<any[]>([]);
  const [managerAccessSearchInputValue, setManagerAccessSearchInputValue] = React.useState('');
  const [showEditBasicInfoModal, setShowEditBasicInfoModal] = useState(false);

  const [formProductNameInputValue, setFormProductNameInputValue] = React.useState('');
  const formProductNameInputValueRef = useRef('');
  const [formPrivateVal, setFormPrivateVal] = React.useState(false);
  const formPrivateValRef = useRef(false);
  const [formRetainVal, setFormRetainVal] = React.useState(false);
  const formRetainValRef = useRef(false);
  const [formProductNameInputError, setFormProductNameInputError] = React.useState(false);
  const [projectOwners, setProjectOwners] = useState<any[]>([]);
  const [productFromHome, setProductFromHome] = useState<any>('');

  const [pageShowCnt, setPageShowCnt] = React.useState<any>('5');

  const [autoScanedUrls, setAutoScanedUrls] = React.useState<any[]>([]);
  const [autoScaneAuthType, setAutoScaneAuthType] = React.useState('');
  const [autoScaneUsername, setAutoScaneUsername] = React.useState('');
  const [autoScanePwd, setAutoScanePwd] = React.useState('');
  const [showEditPagesToScanModal, setShowEditPagesToScanModal] = React.useState(false);
  const [showEditOtherSettingsModal, setShowEditOtherSettingsModal] = React.useState(false);
  const [showEditAccessRequirementsModal, setShowEditAccessRequirementsModal] = React.useState(false);

  const v2SaveUrslSubRef = useRef<Subscription>();
  const [saveChangesLoading, setSaveChangesLoading] = useState(false);
  const v2BasicSettingsSaveChangesRef = useRef<Subscription>();
  const allTeamMembersRef = useRef<any[]>([]);

  const [teamMemberTblCurPage, setTeamMemberTblCurPage] = useState(1);

  const searchUserRef = React.useRef<any>(null);
  const accessModalSaveChangesRef = React.useRef<Subscription>();

  const onStpTabChange = (i: number, value: string) => {
    setStpTabActiveIndex(i);
  };

  const curLoginUser = safelyParseJSON(decryptData(getCookie('user')||'')!);
  const productId = location.state?.productId || (getProductInfoCookie()&&safelyParseJSON(getProductInfoCookie()!)?.id)||sessionStorage.getItem('productId');
  const appId = search.get('appId')!;
  const latestTableDataRef = useRef<any[]>([]);
  const getProductInfoSubscriptionRef = useRef<Subscription>();

  const [pageToScanCurrentPage, setPageToScanCurrentPage] = React.useState(1);

  const [pgToScanUrlsTotalNum, setPgToScanUrlsTotalNum] = useState('');
  const [pgToScanUrlsAllPageCnt, setPgToScanUrlsAllPageCnt] = useState('');

  const [currentProductInfo, setCurrentProductInfo] = useState<any>();

  const [needRefreshFrom, setNeedRefreshFrom] = useState('init');

  const updateEmailNotificationRef = useRef<Subscription>();
  const [latestAssessmentId, setLatestAssessmentId] = useState<any>();

  const [showManageAccessModalIssue, setShowManageAccessModalIssue] = useState(false);
  const [manageAccessModalIssueText, setManageAccessModalIssueText] = useState('');
  const [needRefreshUser, setNeedRefreshUser] = useState('init');
  const [curLoginUserRole, setCurLoginUserRole] = useState('');

  const teamMembersPgCnt = 5;

  const getPrivacySub = useRef<Subscription>();

  const teamMembersDeleteRoleHandler = (members: any[]) => {
    const productOwners = productFromHome?.owners;
    const isPrivate = +productFromHome.isPrivate === 1;

    return members?.map((member) => ({
      ...member,
      canDelete: member?.role === 'owner' || !isPrivate,
      isOwner: member?.role === 'owner',
      email: member?.id,
      hasError: false,
      errorText: '',
    }));
  };

  const getFInalUrls = (arr1: any[] = [], arr2: any[] = []) => {
    let res: any[] = [];
    const arr2Urls = arr2.map((ele) => ele.siteUrl);
    for (let i = 0; i < arr1?.length; i++) {
      let arr1Ele = arr1[i];
      if (arr2Urls.includes(arr1Ele?.siteUrl)) {
        const sameTarget = arr2.filter((ele) => ele.siteUrl === arr1Ele?.siteUrl)[0];
        res.push({
          ...arr1Ele,
          ...sameTarget,
        });
      } else {
        res.push({ ...arr1Ele });
      }
    }
    return res;
  };

  const initHandler = () => {
    setShowLoading(true);
    getProductInfoSubscriptionRef.current = zip(
      productService.getProductInfo(appId),
      assetService.getLatestAssessment(productId),
      assetService.getAssetUrls(productId),
    )
      .pipe(
        take(1),
        map((res) => {
          setLatestAssessmentId(res[1]?.id);
          setCurrentProductInfo(res[0]);
          setProductName(res[0]?.name);
          return { ...res[0], urls: getFInalUrls(res[2]?.data, res[1]?.assessmentJobs) };
        }),
      )
      .subscribe((res: any) => {
        const { ftuxStatus, authType, authUser, authPwd, urls } = res;
        setAutoScanedUrls(urls);
        setAutoScaneAuthType(authType);
        setAutoScaneUsername(authUser);
        setAutoScanePwd(authPwd);
        latestTableDataRef.current = urls.map((url: any, idx: number) => ({
          ...url,
          id: idx + 1,
          pageTitle: url?.nickName,
          url: url?.siteUrl,
          scanStatus: url?.scanStatus,
          action: 'Delete',
        }));
        setPgToScanUrlsTotalNum(urls.length);
        setOriginalUrlTblData(
          latestTableDataRef.current.map((ele, idx) => ({ ...ele, id: idx + 1 })).slice(0, Number(pageShowCnt)),
        );
        setPgToScanUrlsAllPageCnt(Math.ceil(urls.length / Number(pageShowCnt)).toString());

        // if (
        //   [
        //     'editOtherSettingModal',
        //     'editPagesToScanModal',
        //     'editBasicInfoModal',
        //     'editAccessRequirementsModal',
        //   ].includes(needRefreshFrom)
        // ) {
        // }
        setNeedRefreshFrom('xxx');
        if(needRefreshFrom === 'editAccessRequirementsModal') {
          setTimeout(() => {
            (document.querySelector('.settingsPage-accessRequirements')?.querySelector('.settingsPage-basicInfo-titlePart-editIcon') as HTMLElement)?.focus();
          }, 0);
        }
        setTimeout(() => {
          setShowLoading(false);
        }, 3000);
        // setTableTabIndex(containerRef);
      });
  };

  useEffect(() => {
    const appId = search.get('appId');
    let v2AccessRef: Subscription;
    if (appId) {
      v2AccessRef = productService
        .v2GetPrivateAssetMemberDetail(appId)
        .pipe(take(1))
        .subscribe((res) => {
          if (res?.status === 1 && !res?.isMember) {
            navigate('/forbidden', {
              state: {
                origin: 'settings',
                productName: res?.name,
                ownerEmail: res?.ownerName,
              },
            });
          }
        });
    }
    return () => {
      v2AccessRef?.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (
      [
        'editOtherSettingModal',
        'editPagesToScanModal',
        'init',
        'editBasicInfoModal',
        'editAccessRequirementsModal',
      ].includes(needRefreshFrom)
    ) {
      if(location.state?.productId || (getProductInfoCookie()&&safelyParseJSON(getProductInfoCookie()!)?.id)||sessionStorage.getItem('productId')) {
        initHandler();
      }
    }
    return () => {
      getProductInfoSubscriptionRef?.current?.unsubscribe();
      v2SaveUrslSubRef?.current?.unsubscribe();
      v2BasicSettingsSaveChangesRef?.current?.unsubscribe();
    };
  }, [needRefreshFrom, (getProductInfoCookie()&&safelyParseJSON(getProductInfoCookie()!)?.id), sessionStorage.getItem('productId')]);

  const teamMemberHandler = (memberList: any[]) => {
    return memberList?.map((member) => ({
      emailNotification: member?.emailNotification,
      firstName: member?.firstName,
      formattedName: `${member?.firstName} ${member?.lastName}`,
      fullName: `${member?.firstName} ${member?.lastName}`,
      id: member?.userId,
      lastName: member?.lastName,
      role: member?.role,
      productId: member?.productId,
      email: member?.userId,
    }));
  };

  // Table tabIndex
  // const setTableTabIndex = () => {
  //   setTimeout(() => {
  //     const table = (containerRef.current! as HTMLElement).querySelector('.ap-table');
  //     if (table) {
  //       const headers = table.querySelectorAll('.header-container');
  //       headers.forEach(header => {
  //         header.setAttribute('tabindex', '-1');
  //       });
  //     }
  //   }, 200);
  // }
  useEffect(() => {
    let getPrivacy$: Subscription;
    if (['editManageAccessModal', 'init'].includes(needRefreshUser)) {
      setShowLoading(true);
      getPrivacy$ = productService
        .getProductInfo(appId)
        .pipe(
          take(1),
          switchMap((res) => {
            setProductFromHome(res);
            return userService.getMember(res.id);
          }),
          take(1),
        )
        .subscribe((res) => {
          if (res && res?.memberList) {
            const reFormatedMemberList = teamMemberHandler(res?.memberList);
            const temp = changeEleToFirstPlace(reFormatedMemberList, curLoginUser?.id);
            if(res?.memberList && res?.memberList.length > 0) {
              setTeamMembers(teamMembersDeleteRoleHandler(temp));
            }
            setStatictTeamMembers(teamMembersDeleteRoleHandler(temp));

            const finalCurUser = user || safelyParseJSON(decryptData(getCookie('user')||'')!);
            const curUserRole = res?.memberList.filter((m: any) => m.userId === finalCurUser?.id)[0]?.role;
            setCurLoginUserRole(curUserRole);

            allTeamMembersRef.current = reFormatedMemberList?.map((d: any, idx: number) => ({
              id: idx + 1,
              name: d?.fullName,
              role: d?.role,
              email: d?.id,
              emailnotification: d?.emailNotification === 0 ? false : true,
              userInfo: {
                firstName: d?.firstName,
                lastName: d?.lastName,
                formattedName: d?.formattedName,
                id: d?.id,
              },
            }));
            setOriginalTeamMembersTableData(allTeamMembersRef.current?.slice(0, teamMembersPgCnt));
            setShowLoading(false);
            setNeedRefreshUser('xxx');
          }
          // setTableTabIndex(containerRef);
        });
    }
    return () => {
      if (getPrivacy$) getPrivacy$.unsubscribe();
      updateEmailNotificationRef?.current?.unsubscribe();
      accessModalSaveChangesRef?.current?.unsubscribe();
    };
  }, [needRefreshUser]);

  const sortFuncForName1 = (a: any, b: any) => {
    const firstOrderId = (a.name as string).charAt(1);
    const secondOrderId = (b.name as string).charAt(1);
    return firstOrderId > secondOrderId ? 1 : -1;
  };
  const sortFuncForName2 = (a: any, b: any) => {
    const firstOrderId = (a.name as string).charAt(1);
    const secondOrderId = (b.name as string).charAt(1);
    return firstOrderId < secondOrderId ? 1 : -1;
  };

  const onEnToggleChange = (checked: boolean, event: React.SyntheticEvent, rowData: any) => {
    updateEmailNotificationRef.current = productService
      .updateEmailNotification(productId, rowData?.email, checked ? 1 : 0)
      .pipe(take(1))
      .subscribe((res) => {});

    setOriginalTeamMembersTableData((prevs) =>
      prevs.map((prev) => ({
        ...prev,
        emailnotification: rowData.id === prev.id ? checked : prev.emailnotification,
      })),
    );

    setTeamMembers((prevs) =>
      prevs.map((prev) => ({
        ...prev,
        emailNotification: rowData.email === prev.email ? (checked ? 1 : 0) : prev.emailNotification,
      })),
    );
    setStatictTeamMembers((prevs) =>
      prevs.map((prev) => ({
        ...prev,
        emailNotification: rowData.email === prev.email ? (checked ? 1 : 0) : prev.emailNotification,
      })),
    );
  };

  const renderToENCell = (row: any, field: string) => {
    return (
      <Switch
        checked={row.emailnotification}
        onChange={(checked: boolean, event: React.SyntheticEvent) => onEnToggleChange(checked, event, row)}
      >
        {row.emailnotification ? 'On' : 'Off'}
      </Switch>
    );
  };
  const renderToNameCell = (row: any, field: string) => {
    const { userInfo } = row;
    return <AvatarNew userInfo={userInfo} tabIndex={-1} />;
  };

  const renderToRoleCell = (row: any, field: string) => {
    return <span>{rolesArr.filter((role) => role.value === row.role)[0]?.label}</span>;
  };

  const memberTableHeaders = (): any[] => {
    return [
      {
        name: 'Name',
        value: 'name',
        field: 'name',
        renderCell: renderToNameCell,
      },
      {
        name: 'Role',
        value: 'role',
        field: 'role',
        renderCell: renderToRoleCell,
      },
      {
        name: 'Email',
        value: 'email',
        field: 'email',
        renderCell: '',
      },
      {
        name: 'Email notifications',
        value: 'emailnotification',
        field: 'emailnotification',
        renderCell: renderToENCell,
      },
    ];
  };

  const renderToScanStatus = (row: any, field: string) => {
    const { scanStatus } = row;

    const getStatusColor = () => {
      if (scanStatus >= 3) return { color: 'settingsPage-completeColor', text: 'Completed' };
      if (scanStatus >= 2) return { color: 'settingsPage-scaningColor', text: 'Scanning...' };
      if (scanStatus >= 1) return { color: 'settingsPage-pendingColor', text: 'Pending' };
      if (scanStatus < 0) return { color: 'settingsPage-errorColor', text: 'Error' };
      return { color: 'settingsPage-pendingColor', text: 'Pending' };
    };

    return (
      <div className="settingsPage-s-t-scanStatus-icon">
        <span className={`Appkit4-icon icon-hb-full-fill ${getStatusColor()?.color}`}></span>
        <span>{getStatusColor()?.text}</span>
      </div>
    );
  };

  const renderToUrlCell = (row: any, field: string) => {
    return (
      <a href={row.url} target="_blank" rel="noopener noreferrer">
        {row.url}
      </a>
    );
  };

  const renderPageTitle = (row: any, field: string) => {
    return <span>{row.pageTitle?row.pageTitle:"[No title provided]"}</span>;
  };

  const urlTableHeaders = (): any[] => {
    return [
      {
        name: 'Page title',
        value: 'pageTitle',
        field: 'pageTitle',
        renderCell: renderPageTitle
      },
      {
        name: 'URL',
        value: 'url',
        field: 'url',
        renderCell: renderToUrlCell,
      },
      {
        name: 'Scan status',
        value: 'scanStatus',
        field: 'scanStatus',
        renderCell: renderToScanStatus,
      },
    ];
  };

  const openAccessModal = () => {
    setManagerAccessSearchInputValue('');
    setTeamMembers([...statictTeamMembers]);
    teamMembersRef.current = [...statictTeamMembers];
    setShowManageAccessModal(true);
  };

  const getAccessRequirementsAuthTypePartContent = () => {
    let res: any;
    if (autoScaneAuthType === 'idam') {
      res = {
        icon: <span className="Appkit4-icon icon-shield-half-outline settingsPage-accessRequirements-icon"></span>,
        title: "PwC IdAM or IAM’s federated PwC Identity service",
        desc: 'The application uses PwC’s access management services for authentication.',
      };
    } else if (autoScaneAuthType === 'iam') {
      res = {
        icon: <span className="Appkit4-icon icon-shield-outline settingsPage-accessRequirements-icon"></span>,
        title: 'IAM or other authorization services',
        desc: 'Your application uses access management services provided by external companies or third-party services.',
      };
    } else if (autoScaneAuthType === 'public') {
      res = {
        icon: <span className="Appkit4-icon icon-lockopen-unlock-outline settingsPage-accessRequirements-icon"></span>,
        title: 'No login is required',
        desc: 'Access is open. Even external users can access without logging in.',
      };
      // res = {};
    }

    return (
      <>
        {/* {res?.icon}
        <div className="settingsPage-accessRequirements-reviewPart-accessRequirements-content-right">
          <h2>{res?.title}</h2>
          <span>{res?.desc}</span>
        </div> */}

        <div className="settingsPage-accessRequirements-reviewPart-accessRequirements-content-right">
          <div>{res?.title}</div>
          {/* <span>{res?.desc}</span> */}
        </div>
      </>
    );
  };

  const goToAutoScanPage = () => {
    navigate('/autoMatedScan', {
      state: {
        origin: 'dashboard',
        curProductInfo: {
          productId: productId,
          appId: appId,
        },
      },
    });
  };

  const onPageChange = (page: number, pgCnt: number) => {
    let temp = page <= 0 ? 1 : page;
    setPageToScanCurrentPage(temp);
    setOriginalUrlTblData(latestTableDataRef.current.slice((page - 1) * pgCnt, page * pgCnt));
  };

  const onTeamMembersTblPageChange = (page: number) => {
    let temp = page <= 0 ? 1 : page;
    setTeamMemberTblCurPage(temp);
    setOriginalTeamMembersTableData(allTeamMembersRef.current.slice((page - 1) * 5, page * 5));
  };

  const getAccessRequirementsContent = () => {
    let res: any;
    if (['idam', 'iam'].includes(autoScaneAuthType)) {
      res = {
        icon: (
          <span className="Appkit4-icon icon-lockclosed-locked-outline settingsPage-accessRequirements-icon"></span>
        ),
        title: 'Users need to log in or sign up',
        desc: 'User access in your application is restricted. Most PwC applications, except for global or public sites, fall into this category.',
      };
    }
    if (autoScaneAuthType === 'public') {
      res = {
        icon: <span className="Appkit4-icon icon-lockopen-unlock-outline settingsPage-accessRequirements-icon"></span>,
        title: 'No login is required',
        desc: 'User access is not restricted in your application. Even external people can access.',
      };
    }
    return (
      <>
        <div className="settingsPage-accessRequirements-reviewPart-accessRequirements-content-right">
          <div className='content-right-title'>Log-in requirements</div>
          <div className="title-desc">{res?.title}</div>
          {/* <span>
            User access in your product is restricted. Most PwC products, except for global or public sites, fall into
            this category.
          </span> */}
        </div>
      </>
    );
  };

  const getMembersTblShowingParam = () => {
    let start = teamMembersPgCnt * teamMemberTblCurPage - (teamMembersPgCnt - 1);
    let end =
      allTeamMembersRef?.current.length < teamMemberTblCurPage * teamMembersPgCnt
        ? allTeamMembersRef?.current.length
        : teamMemberTblCurPage * teamMembersPgCnt;
    return {
      start,
      end,
    };
  };

  const pageShowNumSelectorHandler = (vals: any) => {
    const tempAllCnt = Number(vals) * Number(pageToScanCurrentPage);
    const finalCurrentPage =
      tempAllCnt > Number(pgToScanUrlsTotalNum)
        ? Math.ceil(Number(pgToScanUrlsTotalNum) / Number(vals))
        : Number(pageToScanCurrentPage);

    setPageToScanCurrentPage(finalCurrentPage);
    setPageShowCnt(vals);
    setPgToScanUrlsAllPageCnt(Math.ceil(latestTableDataRef?.current.length / Number(pageShowCnt)).toString());
    setOriginalUrlTblData(latestTableDataRef?.current?.slice(finalCurrentPage - 1, finalCurrentPage * Number(vals))); //?????
  };

  const generalContent = () => {
    if (stpTabActiveIndex === 0) {
      return teamMembers.length === 0 ? (
        <div className="settingsPage-noMemberPage">
          <div className="imgPart">
            <img src={NoMembersPageLogo} alt="No issue icon" />
          </div>
          <div className="boldTitlePart">No team members added.</div>
          <div className="descPart">Add your team members to gain access to the application.</div>
          <div className="runAutoScanPart">
            <Button kind="primary" icon={'icon-plus-outline'}>
              Add team members
            </Button>
          </div>
        </div>
      ) : (
        <div className={'settingsPage-teamers'}>
          <div className={'settingsPage-teamers-headerBtn'}>
            <h2>Team members</h2>
            <Button kind={'secondary'} onClick={openAccessModal}>
              Manage access
            </Button>
          </div>

          <div className={'settingsPage-teamers-tableArea'}>
            {allTeamMembersRef?.current?.length >= teamMembersPgCnt ? (
              <div className={'settingsPage-teamers-tableArea-topText'}>
                Showing {getMembersTblShowingParam().start}-{getMembersTblShowingParam().end} of{' '}
                {allTeamMembersRef?.current.length}
              </div>
            ) : null}

            <div className={'settingsPage-teamers-tableArea-table'}>
              <Table
                originalData={originalTeamMembersTableData}
                animatedSorting={false}
                disableDefaultSort
                hasTitle
                striped
                condensed
                className="table-overflow"
              >
                {memberTableHeaders().map((header: any, idx: number) => (
                  <Column
                    key={`${idx}-${header.value}`}
                    field={header.field}
                    sortKey={header.sortKey}
                    sortFunc1={header.sortFunc1}
                    sortFunc2={header.sortFunc2}
                    renderCell={header.renderCell}
                  >
                    {header.name}
                  </Column>
                ))}
              </Table>
            </div>
            {allTeamMembersRef?.current?.length >= teamMembersPgCnt ? (
              <div className="scanModal-tablePart-pagnation">
                <Pagination
                  current={teamMemberTblCurPage}
                  total={Math.ceil(allTeamMembersRef?.current.length / 5)}
                  onPageChange={onTeamMembersTblPageChange}
                  toPreviousPage=""
                  toNextPage=""
                ></Pagination>
              </div>
            ) : null}
          </div>
        </div>
      );
    }
    if (stpTabActiveIndex === 1) {
      return (
        <>
          <div className={'settingsPage-autoScan'}>
            <div className={'settingsPage-autoScan-titleModifyBtn'}>
              <div className={'settingsPage-autoScan-titleModifyBtn-titlePart'}>
                <h2>Pages to scan</h2>
                <span
                  tabIndex={0}
                  className="Appkit4-icon icon-edit-outline settingsPage-basicInfo-titlePart-editIcon"
                  role="button"
                  aria-label="edit pages to scan"
                  onKeyDown={(e: React.KeyboardEvent) => {
                    if (e.key === 'Enter') {
                      setShowEditPagesToScanModal(true);
                    }
                  }}
                  onClick={() => {
                    setShowEditPagesToScanModal(true);
                  }}
                ></span>
              </div>
              {latestTableDataRef?.current?.filter((u) => u.scanStatus < 0)?.length > 0 ? (
                <div className={'settingsPage-autoScan-titleModifyBtn-issuePart'}>
                  <span className="Appkit4-icon icon-information-outline recoDescIcon"></span>
                  <div>
                    <span>Scan errors presented during the last scan. Please contact us for assistance.</span>
                    <a
                      href="https://forms.office.com/Pages/ResponsePage.aspx?id=oJQyUSA-skGpcG0wvxVG-qJtLN_BonZFrxiPNTnvCsRUNFUzWUwySEdIUjJWN1RXNkhENjYwUjhNNC4u"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Contact us
                    </a>
                  </div>
                </div>
              ) : null}
            </div>
            <div className={'settingsPage-autoScan-urlTablePart'}>
              <div className={'settingsPage-autoScan-urlTablePart-topText'}>
                <span>
                  Showing {Number(pageShowCnt) * Number(pageToScanCurrentPage) - Number(pageShowCnt) + 1}-
                  {Number(pageShowCnt) * Number(pageToScanCurrentPage) > Number(pgToScanUrlsTotalNum)
                    ? pgToScanUrlsTotalNum
                    : Number(pageShowCnt) * Number(pageToScanCurrentPage)}{' '}
                  of {pgToScanUrlsTotalNum}
                </span>
                {latestTableDataRef?.current?.filter((u) => u.scanStatus < 0)?.length > 0 ? (
                  <div className={'settingsPage-autoScan-urlTablePart-topText-unreachablePart'}>
                    <div>
                      <span
                        className={`Appkit4-icon icon-hb-full-fill settingsPage-autoScan-urlTablePart-topText-erorColor`}
                      ></span>
                    </div>
                    <div>
                      <span>Unreachable URL.</span>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className={'settingsPage-autoScan-urlTablePart-table'}>
                <Table originalData={originalUrlTblData} hasTitle striped condensed>
                  {urlTableHeaders().map((header: any, idx: number) => (
                    <Column
                      key={`${idx}-${header.value}`}
                      field={header.field}
                      sortKey={header.sortKey}
                      sortFunc1={header.sortFunc1}
                      sortFunc2={header.sortFunc2}
                      renderCell={header.renderCell}
                    >
                      {header.name}
                    </Column>
                  ))}
                </Table>
              </div>
              <div className="settingsPage-autoScan-urlTablePart-pageNationPart">
                <div className="settingsPage-autoScan-urlTablePart-pageNationPart-showPart">
                  <span>Show</span>
                  <div className="settingsPage-autoScan-urlTablePart-pageNationPart-showPart-pageDropdownPart">
                    <Select
                      data={urlPartSelectPart}
                      value={pageShowCnt}
                      searchable={false}
                      defaultValue={['5']}
                      placeholder=""
                      hideTitleOnInput={true}
                      dropdownAlwaysDown={false}
                      onSelect={pageShowNumSelectorHandler}
                    ></Select>
                  </div>
                  <span>items per page</span>
                </div>
                <div className="settingsPage-autoScan-urlTablePart-pageNationPart-showPart-pagePart">
                  <Pagination
                    current={pageToScanCurrentPage}
                    total={Math.ceil(latestTableDataRef?.current.length / Number(pageShowCnt))}
                    onPageChange={(page) => onPageChange(page, Number(pageShowCnt))}
                    toPreviousPage=""
                    toNextPage=""
                  ></Pagination>
                </div>
              </div>
            </div>
          </div>

          <div className={'settingsPage-accessRequirements'}>
            {autoScaneAuthType ? (
              <div className="settingsPage-accessRequirements-reviewPart-accessRequirements">
                <div className="settingsPage-accessRequirements-reviewPart-accessRequirements-title">
                  <h2>Access requirements</h2>
                  <span
                    tabIndex={0}
                    onKeyDown={(e: React.KeyboardEvent) => {
                      if (e.key === 'Enter') {
                        setShowEditAccessRequirementsModal(true);
                      }
                    }}
                    className="Appkit4-icon icon-edit-outline settingsPage-basicInfo-titlePart-editIcon"
                    role="button"
                    aria-label="edit access requirements"
                    onClick={() => {
                      setShowEditAccessRequirementsModal(true);
                    }}
                  ></span>
                </div>
                <div className="settingsPage-accessRequirements-reviewPart-accessRequirements-content">
                {getAccessRequirementsContent()}
                {autoScaneAuthType === 'public' ? null : (
                <div className="settingsPage-accessRequirements-reviewPart-accessRequirements-authTypePart">
                  <div className="settingsPage-accessRequirements-reviewPart-accessRequirements-authTypePart-title">
                    Authentication type
                  </div>
                  <div className="settingsPage-accessRequirements-reviewPart-accessRequirements-authTypePart-content">
                    {getAccessRequirementsAuthTypePartContent()}
                  </div>
                  
                </div>
              )}
              </div>
              {autoScaneAuthType && autoScaneAuthType === 'iam' && (
                    <div className="settingsPage-accessRequirements-reviewPart-accessRequirements-adminPart">
                      <div className="settingsPage-accessRequirements-reviewPart-accessRequirements-adminPart-title">
                        <div>Admin user credentials</div>
                        <div>{autoScaneUsername}</div>
                      </div>
                      <div className="settingsPage-accessRequirements-reviewPart-accessRequirements-adminPart-content">
                        <div className='password-title'>Password</div>
                        <div className="password-mask">{autoScanePwd}</div>
                      </div>
                    </div>
                  )}
              </div>
            ) : (
              <div className="settingsPage-accessRequirements-no">
                <NoResultsPage
                  imgSrc={NoAccessRequirementsSvg}
                  imgAlt="no access requirements logo"
                  titleArea="No access requirements set up."
                  descArea="Finish setting up the automated scan."
                  btnArea={
                    <Button kind="primary" onClick={goToAutoScanPage}>
                      Set up automated scan
                    </Button>
                  }
                />
              </div>
            )}
          </div>
        </>
      );
    }
  };

  const userInfohandler = (userInfo: any) => {
    const namePart = userInfo?.id?.split('@')[0];
    const firstName = capitalizeFirstLetter(userInfo.firstName ? userInfo.firstName : namePart?.split('.')[0]);
    const lastName = capitalizeFirstLetter(userInfo.lastName ? userInfo.lastName : namePart?.split('.')[1]);
    return {
      ...userInfo,
      firstName,
      lastName,
      formattedName: userInfo?.formattedName
        ? userInfo.formattedName
        : `${capitalizeFirstLetter(firstName)} ${capitalizeFirstLetter(lastName)}`,
    };
  };

  const handleSelect = (value: string, item: any, event: React.SyntheticEvent) => {
    let _res: any[] = [];
    const email = item?.email;
    const _temp = { ...userInfohandler(item), hasError: false, errorText: '', email: email ? email : item?.id };
    if (teamMembers?.length === 0) {
      _res =
        email === user?.id
          ? [_temp]
          : [
              {
                guid: user?.guid,
                email: user?.id,
                formattedName: user?.formattedName,
                firstName: user?.firstName,
                lastName: user?.lastName,
                hasError: false,
                errorText: '',
              },
              _temp,
            ];
    } else {
      const filterRes = teamMembers.filter((usr) => usr?.email === email);
      _res = filterRes.length === 0 ? [...teamMembers, _temp] : [...teamMembers];
    }

    setManagerAccessSearchInputValue('');
    setTeamMembers([..._res]);
  };

  const onAddTeamerSearchChange = async (
    value: string,
    event: React.SyntheticEvent | React.ChangeEvent<HTMLInputElement>,
  ) => {
    setManagerAccessSearchInputValue(value);
    if (!value) return;

    const res: any = await axios.post(`/user/search`, {
      page: 1,
      size: 10,
      query: value,
      type: 'FULL',
    });

    setManagerAccessUserSearchList(
      res.map((item: any) => ({
        ...item,
        value: item?.email,
        label: item?.formattedName,
      })),
    );
    if (res?.length > 0)
      setMessageResult?.(`${res?.length} results found`);
    else
      setMessageResult?.('No results found');
  };

  const accessModalSaveChanges = () => {
    const ownerUser = teamMembers?.filter((ele) => ele.role === 'owner');
    if (ownerUser.length === 0) {
      setShowManageAccessModalIssue(true);
      setManageAccessModalIssueText('At least one owner is required.');
      setSaveChangesLoading(false);
      return;
    }

    const _cUser = teamMembers.filter((ele) => ele.email === curLoginUser?.id)[0];
    setCookie('user', encryptData({ ...curLoginUser, role: _cUser?.role }));

    const param = {
      productId,
      members: teamMembers.map((t) => ({
        ...t,
        email: t?.email || t?.id,
        role: t?.role,
        // emailNotification: t.emailNotification ? t.emailNotification : 0,
      })),
    };
    accessModalSaveChangesRef.current = productService
      .v2UpdateMember(param)
      .pipe(take(1))
      .subscribe((res) => {
        setNeedRefreshUser('editManageAccessModal');
        setSaveChangesLoading(false);
        setShowManageAccessModal(false);
      });
  };

  const getIssueText = (txt: string) => {
    setManageAccessModalIssueText(txt);
  };
  const getMASaveBtnDisabledStatus = useMemo(() => {
    if (teamMembersRef.current?.length !== teamMembers?.filter(item => item.role && item.role.trim() !== '')?.length) return false;
    if (manageAccessModalIssueText||isEqual(teamMembersRef.current, teamMembers)) return true;
    const res = teamMembers?.filter((ele) => !ele.role);
    if (res?.length > 0) return true;
    
    return false;
  }, [teamMembers]);

  const accessModalContent = {
    title: 'Manage access',
    body: (
      <div className="settingsPage-modalBody">
        <div className="settingsPage-modalBody-addTeamerPart">
          <div className="settingsPage-modalBody-addTeamerPart-search">
            <div className="settingsPage-modalBody-addTeamerPart-search-container">
              <Search
                data={managerAccessUserSearchList}
                searchType={'secondary'}
                searchValue={managerAccessSearchInputValue}
                onSelect={handleSelect}
                onChange={onAddTeamerSearchChange}
                placeholder="Search name"
                onClear={() => {
                  setManagerAccessSearchInputValue('');
                }}
              />
            </div>
            <div className="settingsPage-modalBody-addTeamerPart-search-contentContainer">
              {teamMembers?.map((usr: any, idx: number) => (
                <SelectedUser
                  key={`${idx}-selectedUserPart`}
                  user={usr}
                  setSelectedUsers={setTeamMembers}
                  origin={'settings'}
                  selectedUsers={teamMembers}
                  setShowManageAccessModalIssue={setShowManageAccessModalIssue}
                  getIssueText={getIssueText}
                  curLoginUserRole={curLoginUserRole}
                  uuid={uuid()}
                />
              ))}
            </div>
          </div>
        </div>
        {showManageAccessModalIssue ? (
          <div className="settingsPage-modalBody-issue">
            <span className={'recomDescContainer'}>
              <span className="Appkit4-icon icon-information-outline recoDescIcon"></span>
              <span>{manageAccessModalIssueText}</span>
            </span>
          </div>
        ) : null}
      </div>
    ),
    footer: (
      <div className="settingsPage-modalFooter">
        <div className="settingsPage-modalFooter-btnArea">
          <Button
            onClick={() => {
              setShowManageAccessModalIssue(false);
              getIssueText('');
              setShowManageAccessModal(false);
              setTeamMembers((prevs) => prevs.map((ele) => ({ ...ele, hasError: false })));
            }}
            kind="text"
          >
            Cancel
          </Button>
          <Button
            disabled={getMASaveBtnDisabledStatus}
            loading={saveChangesLoading}
            onClick={() => {
              setSaveChangesLoading(true);
              accessModalSaveChanges();
            }}
          >
            Save changes
          </Button>
        </div>
      </div>
    ),
  };

  const onProductNameInputChange = (value: string, event: any) => {
    setFormProductNameInputValue(value);
    // todo 1 去校验是否名字重复
    // setFormProductNameInputError(true);
    // todo 2 是否为空 等等
  };

  const getFormProductNameInputErrorNode = () => {
    if (formProductNameInputError) {
      return (
        <span className="settingsPage-basicInfoModal-modalBody-productName-inputError">
          Application name already exists. Check if this application already exists or use a different one.{' '}
        </span>
      );
    }
  };

  const onPrivateToggleChange = (checked: boolean, event: React.SyntheticEvent) => {
    setFormPrivateVal((prev) => !prev);
  };
  const onRetainToggleChange = (checked: boolean, event: React.SyntheticEvent) => {
    setFormRetainVal((prev) => !prev);
  };

  const openBasicInfoModal = () => {
    setFormProductNameInputValue(currentProductInfo?.name);
    formProductNameInputValueRef.current = currentProductInfo?.name;
    setFormPrivateVal(currentProductInfo?.isPrivate === 1 ? true : false);
    formPrivateValRef.current = currentProductInfo?.isPrivate === 1 ? true : false;
    setFormRetainVal(currentProductInfo?.dataRetention === 1 ? true : false);
    formRetainValRef.current = currentProductInfo?.dataRetention === 1 ? true : false;

    setShowEditBasicInfoModal(true);
  };
  const openOtherSettingsModal = () => {
    setShowEditOtherSettingsModal(true);
  };

  const basicInfosaveChangesHandler = () => {
    setSaveChangesLoading(true);
    const productId = (getProductInfoCookie()&&safelyParseJSON(getProductInfoCookie()!)?.id)||sessionStorage.getItem('productId');
    const param = {
      productId: String(productId),
      name: formProductNameInputValue,
      isPrivate: formPrivateVal ? 1 : -1,
      yesOrNo: formRetainVal ? 1 : -1,
    };
    v2BasicSettingsSaveChangesRef.current = productService
      .v2BasicSettingsSaveChanges(param)
      .pipe(take(1))
      .subscribe((res) => {
        if (res?.isNameExist === 1) {
          setSaveChangesLoading(false);
          setFormProductNameInputError(true);
          return;
        }
        if (res?.status?.toLowerCase() === 'success') {
          setProductName(formProductNameInputValue);
          setSaveChangesLoading(false);
          setShowEditBasicInfoModal(false);
          setNeedRefreshFrom('editBasicInfoModal');
        }
      });
  };

  const getDataRetentionContent = () => {
    if (latestAssessmentId) {
      const assessmentUpdatedAt = currentProductInfo?.lastestAssessment?.updatedAt;
      let retentionTime: any = 1;
      if (showEditBasicInfoModal) {
        if (formRetainVal) {
          retentionTime = 2;
        } else {
          retentionTime = 1;
        }
      } else {
        retentionTime = currentProductInfo?.dataRetention === 1 ? 2 : 1;
      }
      return `Data will be deleted on ${moment(moment(assessmentUpdatedAt).add(retentionTime, 'years')).format('LL')}`;
    }
    return 'Data retention is not available until the next assessment completed.';
  };

  const basicInfoModalContent = {
    title: 'Basic information',
    body: (
      <div className="settingsPage-basicInfoModal-modalBody">
        <div className="settingsPage-basicInfoModal-modalBody-productName">
          <Input
            type={'text'}
            title={'Application name'}
            allowClear={true}
            required={true}
            value={formProductNameInputValue}
            onChange={onProductNameInputChange}
            onClear={() => {
              setFormProductNameInputError(false);
              setFormProductNameInputValue('');
            }}
            error={formProductNameInputError}
            errorNode={getFormProductNameInputErrorNode()}
          ></Input>
        </div>
        <div className="settingsPage-basicInfoModal-modalBody-privacyPart">
          <div className="settingsPage-basicInfoModal-modalBody-privacyPart-title">Privacy</div>
          <div className="settingsPage-basicInfoModal-modalBody-privacyPart-toggle">
            <Switch
              className="settingsPage-basicInfoModal-modalBody-privacyPart-toggle-switch"
              checked={formPrivateVal}
              onChange={onPrivateToggleChange}
            >
              Set as private
            </Switch>
          </div>
          <div className="settingsPage-basicInfoModal-modalBody-privacyPart-desc">
            <span className="Appkit4-icon icon-circle-warning-outline"></span>
            {formPrivateVal === false ? (
              <span>Anyone can access and manage this application.</span>
            ) : (
              <span>Only team members can access and manage this application.</span>
            )}
          </div>
        </div>
        <div className="settingsPage-basicInfoModal-modalBody-dataRetentionPart">
          <div className="settingsPage-basicInfoModal-modalBody-dataRetentionPart-title">Data retention</div>
          <div className="settingsPage-basicInfoModal-modalBody-dataRetentionPart-toggle">
            <Switch
              className="settingsPage-basicInfoModal-modalBody-dataRetentionPart-toggle-switch"
              checked={formRetainVal}
              onChange={onRetainToggleChange}
              disabled={!latestAssessmentId}
            >
              Retain data for 2 years.
            </Switch>
          </div>
          <div className="settingsPage-basicInfoModal-modalBody-dataRetentionPart-desc">
            <span className="Appkit4-icon icon-circle-warning-outline"></span>
            <span>{getDataRetentionContent()}</span>
          </div>
        </div>
      </div>
    ),
    footer: (
      <div className="settingsPage-basicInfoModal-modalFooter">
        <div className="settingsPage-basicInfoModal-modalFooter-descArea">
          <span>Required Fields</span>
        </div>
        <div className="settingsPage-basicInfoModal-modalFooter-btnArea">
          <Button onClick={() => setShowEditBasicInfoModal(false)} kind="text">
            Cancel
          </Button>
          <Button
            onClick={() => {
              setSaveChangesLoading(true);
              basicInfosaveChangesHandler();
            }}
            kind="primary"
            loading={saveChangesLoading}
            disabled={!formProductNameInputValue||(formProductNameInputValueRef.current===formProductNameInputValue
              && formPrivateValRef.current === formPrivateVal && formRetainValRef.current === formRetainVal
            )}
          >
            Save changes
          </Button>
        </div>
      </div>
    ),
  };

  const getScheduledLabel = () => {
    const enabled = currentProductInfo?.scheduleEnable;
    if (enabled === -1 || !enabled) return 'No';
    const scheduleFrequency = currentProductInfo?.scheduleFrequency;
    if (scheduleFrequency === 1) return 'Weekly on monday';
    if (scheduleFrequency === 2) return 'Bi-weekly on monday';
    if (scheduleFrequency === 3) return 'The first day of a month';
    return '';
  };
  const getCrawlStatusLabel = () => {
    const crawlStatus = currentProductInfo?.crawlStatus;
    if (crawlStatus === 1) return 'Yes';
    return 'No';
  };

  const getDataRetentionFlag = () => {
    if (latestAssessmentId) {
      return currentProductInfo?.dataRetention === 1 ? 'Yes' : 'No';
    }
    return 'No accessibility data';
  };

  const generateOtherSettingsInfo = () => {
    return stpTabActiveIndex === 0 ? (
      <div className={'settingsPage-basicInfo'}>
        <div className={'settingsPage-basicInfo-titlePart'}>
          <h2>Basic information</h2>
          <span
            tabIndex={0}
            className="Appkit4-icon icon-edit-outline settingsPage-basicInfo-titlePart-editIcon"
            role="button"
            aria-label="edit basic information"
            onKeyDown={(e: React.KeyboardEvent) => {
              if (e.key === 'Enter') {
                openBasicInfoModal();
              }
            }}
            onClick={openBasicInfoModal}
          ></span>
        </div>
        <div className={'settingsPage-basicInfo-productName'}>
          <h3>Application name</h3>
          <span>{currentProductInfo?.name}</span>
        </div>
        <div className={'settingsPage-basicInfo-privatePart'}>
          <h3>Private application</h3>
          <span className={'settingsPage-basicInfo-privatePart-flag'}>
            {currentProductInfo?.isPrivate === 1 ? 'Yes' : 'No'}
          </span>
          <div className={'settingsPage-basicInfo-privatePart-issue'}>
            <span className={'recomDescContainer'}>
              <span className="Appkit4-icon icon-information-outline recoDescIcon"></span>
              {currentProductInfo?.isPrivate === 1 ? (
                <span>Only team members can access and manage this application.</span>
              ) : (
                <span>Anyone can access and manage this application.</span>
              )}
            </span>
          </div>
        </div>
        <div className={'settingsPage-basicInfo-dataRetenion'}>
          <h3>Data retention</h3>
          <span className={'settingsPage-basicInfo-dataRetenion-flag'}>{getDataRetentionFlag()}</span>
          <div className={'settingsPage-basicInfo-dataRetenion-issue'}>
            <span className={'recomDescContainer'}>
              <span className="Appkit4-icon icon-information-outline recoDescIcon"></span>
              {getDataRetentionContent()}
            </span>
          </div>
        </div>
      </div>
    ) : (
      <div className={'settingsPage-otherSettings'}>
        <div className={'settingsPage-otherSettings-titlePart'}>
          <h2>Other settings</h2>
          <span
            tabIndex={0}
            className="Appkit4-icon icon-edit-outline settingsPage-otherSettings-titlePart-editIcon"
            role="button"
            aria-label="edit other setting"
            onKeyDown={(e: React.KeyboardEvent) => {
              if (e.key === 'Enter') {
                openOtherSettingsModal();
              }
            }}
            onClick={openOtherSettingsModal}
          ></span>
        </div>
        <div className={'settingsPage-otherSettings-productName'}>
          <h3>Automatic scans</h3>
          {currentProductInfo && <div>{getScheduledLabel()}</div>}
        </div>
        {currentProductInfo?.scheduleEnable === -1 || !currentProductInfo?.scheduleEnable ? null : (
          <div className={'settingsPage-otherSettings-privatePart'}>
            <div className={'settingsPage-otherSettings-privatePart-issue'}>
              <span className={'recomDescContainer'}>
                <span className="Appkit4-icon icon-information-outline recoDescIcon"></span>
                {currentProductInfo && (
                  <span>Next scan will be on {moment(currentProductInfo?.scheduleDate).format('LL')}.</span>
                )}
              </span>
            </div>
          </div>
        )}

        <div className={'settingsPage-otherSettings-dataRetenion'}>
          <div>
            <h3>Automatic search of additional pages</h3>
            <div className={'settingsPage-otherSettings-dataRetenion-flag'}>{getCrawlStatusLabel()}</div>
          </div>

          {getCrawlStatusLabel() === 'Yes' ? (
            <div className={'settingsPage-otherSettings-dataRetenion-issue'}>
              <span className={'recomDescContainer'}>
                <span className="Appkit4-icon icon-information-outline recoDescIcon"></span>
                <span>Up to three pages will be searched for each page added.</span>
              </span>
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const saveChangesClickHandler = (latestUrls: any[]) => {
    setSaveChangesLoading(true);
    const param = {
      productId: String(productId),
      urls: latestUrls.map((data) => ({
        siteUrl: data?.url,
        nickName: data?.pageTitle,
      })),
    };
    v2SaveUrslSubRef.current = productService
      .v2SaveAutoScanUrls(param)
      .pipe(
        take(1),
        switchMap((res: any) => {
          if (res?.status?.toLowerCase() === 'success') {
            setSaveChangesLoading(false);
            setShowEditPagesToScanModal(false);
            return assetService.getAssetUrls(productId);
          }
          return of(res);
        }),
        take(1),
      )
      .subscribe((res) => {
        setPageToScanCurrentPage(1);
        setNeedRefreshFrom('editPagesToScanModal');
      });
  };

  const getDetailContent = () => {
    if (showLoading) {
      return <LoadingComponent />;
    } else {
      if (!latestAssessmentId && stpTabActiveIndex === 1) {
        return (
          <div className="settingsPage-noScans">
            <NoResultsPage
              imgSrc={NoScansSvg}
              imgAlt="No scans to manage logo"
              titleArea="No scans to manage."
              descArea="To get started, please select set up an automated scan to begin your journey to accessible and inclusive experiences. "
              btnArea={
                <Button kind="primary" onClick={goToAutoScanPage}>
                  Set up automated scan
                </Button>
              }
            />
          </div>
        );
      }

      return (
        <>
          <div className={'col-9'}>{generalContent()}</div>
          <div className={'col-3'}>{generateOtherSettingsInfo()}</div>
        </>
      );
    }
  };

  return (
    <>
      <div className={'settingsPage'} ref={containerRef} tabIndex={-1}>
        <div className="row">
          <h1 className="settingsPage-settingsLabel">Settings</h1>
        </div>
        <div className="row">
          <div className="settingsPage-tabsContainer">
            <Tabs type="underline" activeIndex={stpTabActiveIndex} onTabChange={onStpTabChange}>
              {settingsPageTabLabels.map((label: { name: string; value: string }, i: number) => (
                <Tab label={label.name} value={label.value} key={`${i}-${label.value}`}></Tab>
              ))}
            </Tabs>
          </div>
        </div>
        <div className="row">
          {showLoading ? (
            <LoadingComponent />
          ) : (
            <>
              {!latestAssessmentId && stpTabActiveIndex === 1 ? (
                <div className="settingsPage-noScans">
                  <NoResultsPage
                    imgSrc={NoScansSvg}
                    imgAlt="No scans to manage logo"
                    titleArea="No scans to manage."
                    descArea="To get started, please select set up an automated scan to begin your journey to accessible and inclusive experiences. "
                    btnArea={
                      <Button kind="primary" onClick={goToAutoScanPage}>
                        Set up automated scan
                      </Button>
                    }
                  />
                </div>
              ) : (
                <>
                  <div className="col-9 settingsPage-col9Style">{generalContent()}</div>
                  <div className="col-12 settingsPage-col9To12Style">{generalContent()}</div>
                  <div className={'col-3 settingsPage-col3Style'}>{generateOtherSettingsInfo()}</div>
                </>
              )}
            </>
          )}
        </div>
        <div className="row">
          <div className="col-12 settingsPage-col3To12Style"> {generateOtherSettingsInfo()}</div>
        </div>
      </div>
      <CommonModal
        visible={showManageAccessModal}
        modalContent={accessModalContent}
        setVisibleFunc={setShowManageAccessModal}
        className="settings-accessModal"
        bodyStyle={''}
      />
      <CommonModal
        visible={showEditBasicInfoModal}
        modalContent={basicInfoModalContent}
        setVisibleFunc={setShowEditBasicInfoModal}
        className="settings-basicInfoModal"
        bodyStyle={''}
      />
      <EditPagesToScanModal
        visible={showEditPagesToScanModal}
        setVisibleFunc={setShowEditPagesToScanModal}
        paramTblData={latestTableDataRef.current}
        saveChangesClickHandler={saveChangesClickHandler}
        saveChangesLoading={saveChangesLoading}
      />
      <EditOtherSettingsModal
        visible={showEditOtherSettingsModal}
        setVisibleFunc={setShowEditOtherSettingsModal}
        currentProductInfo={currentProductInfo}
        setNeedRefreshFrom={setNeedRefreshFrom}
        uuid={uuid()}
      />
      <EditAccessRequirementsModal
        visible={showEditAccessRequirementsModal}
        setVisibleFunc={setShowEditAccessRequirementsModal}
        currentProductInfo={currentProductInfo}
        setNeedRefreshFrom={setNeedRefreshFrom}
        uuid={uuid()}
        autoScaneAuthType={autoScaneAuthType}
        autoScaneUsername={autoScaneUsername}
        autoScanePwd={autoScanePwd}
      />
    </>
  );
};
export default SettingsPage;

import React, { useState, useRef, useEffect, useMemo, useContext } from 'react';
import './index.scss';
import { Modal } from '@appkit4/react-components/modal';
import { Button } from '@appkit4/react-components/button';
import { Tooltip } from '@appkit4/react-components/tooltip';
import { Switch } from '@appkit4/react-components/switch';
import { Select, Badge, Radio, Drawer, RadioGroup } from '@appkit4/react-components';
import { Subscription, of, zip } from 'rxjs';
import { switchMap, concatMap, filter } from 'rxjs/operators';
import { productService } from '@services';
import { debounceTime, distinctUntilChanged, map, take } from 'rxjs/operators';
import { idamOrIamExpandData, authenticationTypesDataForSettingsPage } from '@layout/AutoMatedScan.page/constants';
import LoggedOutSvg from '@assets/icons/LoggedOut.svg';
import ScanInProgressSvg from '@assets/icons/scanInProgress.svg';
import ConsultingCyberSecuritySvg from '@assets/icons/ConsultingCyberSecurity.svg';
import UpdatedPasswordUpdatedSvg from '@assets/icons/UpdatedPasswordUpdated.svg';
import { CheckboxGroup, Checkbox } from '@appkit4/react-components/checkbox';
import { Input } from '@appkit4/react-components/field';
import { assetService } from '@services/asset.service';
import { Accordion, AccordionItem } from '@appkit4/react-components/accordion';
import { decryptData, getCookie, getProductInfoCookie, safelyParseJSON } from '@utils/common';
import { isEqual } from 'lodash';
import classNames from 'classnames';

const EditAccessRequirementsModal = (props: any) => {
  const {
    visible = true,
    setVisibleFunc,
    bodyStyle = {},
    curProductInfo,
    setNeedRefreshFrom,
    uuid,
    autoScaneAuthType,
    autoScaneUsername,
    autoScanePwd,
  } = props;
  const [messageResultInfo, setMessageResultInfo] = useState<string>('');
  const [uniqueKey, setUniqueKey] = useState(0);
  const [authTypeData, setAuthTypeData] = useState(authenticationTypesDataForSettingsPage);
  const [allCanAccessOptionClicked, setAllCanAccessOptionClicked] = useState(false);
  const [mustLogOptionClicked, setMustLogOptionClicked] = useState(false);
  const [idamOrIamCheckboxChecked, setIdamOrIamCheckboxChecked] = useState(false);
  const [iamOrOtherUserNameValue, setIamOrOtherUserNameValue] = useState('');
  const [iamOrOtherPasswordValue, setIamOrOtherPasswordValue] = useState('');
  const [saveChangesLoading, setSaveChangesLoading] = useState(false);
  const [finalClickedAccessRequirements, setFinalClickedAccessRequirements] = useState('');
  const [finalAuthTypeUsed, setFinalAuthTypeUsed] = useState('');
  const [selectedItem, setSelectedItem] = useState<any>();
  const [accessRequirementsValue, setAccessRequirementsValue] = useState<any>(null);
  const [authtypeValue, setAuthtypeValue] = useState<any>(null);
  const updateAssetCredentialRef = useRef<Subscription>();
  const authTypeDataRef = useRef<any>();
  const allCanAccessOptionClickedRef = useRef<boolean>();
  const mustLogOptionClickedRef = useRef<boolean>();
  const idamOrIamCheckboxCheckedRef = useRef<boolean>();
  const iamOrOtherUserNameValueRef = useRef<string>();
  const iamOrOtherPasswordValueRef = useRef<string>();
  const finalClickedAccessRequirementsRef = useRef<string>();
  const finalAuthTypeUsedRef = useRef<string>();
  const [accoringActiveKeys, setAccoringActiveKeys] = React.useState<string[]>([]);

  const initHandler = () => {
    if (autoScaneAuthType === 'idam') {
      setIdamOrIamCheckboxChecked(false);
      setFinalAuthTypeUsed('idamOrIam');
      setFinalClickedAccessRequirements('mustLog');
      setMustLogOptionClicked(true);
      setAllCanAccessOptionClicked(false);
      setIamOrOtherUserNameValue('');
      setIamOrOtherPasswordValue('');
      // setIdamOrIamCheckboxChecked(true);
      setAuthTypeData((prevs) =>
        prevs?.map((prev) => ({ ...prev, clicked: prev?.value === 'idamOrIam' ? true : false })),
      );
      authTypeDataRef.current = authTypeData.map((prev) => ({ ...prev, clicked: prev?.value === 'idamOrIam' ? true : false }));
      allCanAccessOptionClickedRef.current = false;
      mustLogOptionClickedRef.current = true;
      idamOrIamCheckboxCheckedRef.current = false;
      iamOrOtherUserNameValueRef.current = '';
      iamOrOtherPasswordValueRef.current = '';
      finalClickedAccessRequirementsRef.current = 'mustLog';
      finalAuthTypeUsedRef.current = 'idamOrIam';
      setAccessRequirementsValue('0');
      setAuthtypeValue('0');
    } else if (autoScaneAuthType === 'iam') {
      setIdamOrIamCheckboxChecked(false);
      setFinalAuthTypeUsed('iamOrOther');
      setFinalClickedAccessRequirements('mustLog');
      setIamOrOtherUserNameValue(autoScaneUsername);
      setIamOrOtherPasswordValue(autoScanePwd ? autoScanePwd : '');
      setMustLogOptionClicked(true);
      setAllCanAccessOptionClicked(false);
      setAuthTypeData((prevs) =>
        prevs?.map((prev) => ({ ...prev, clicked: prev?.value === 'iamOrOther' ? true : false })),
      );
      authTypeDataRef.current = authTypeData.map((prev) => ({ ...prev, clicked: prev?.value === 'iamOrOther' ? true : false }));
      allCanAccessOptionClickedRef.current = false;
      mustLogOptionClickedRef.current = true;
      idamOrIamCheckboxCheckedRef.current = false;
      iamOrOtherUserNameValueRef.current = autoScaneUsername;
      iamOrOtherPasswordValueRef.current = autoScanePwd ? autoScanePwd : '';
      finalClickedAccessRequirementsRef.current = 'mustLog';
      finalAuthTypeUsedRef.current = 'iamOrOther';
      setAccessRequirementsValue('0');
      setAuthtypeValue('1');
    } else if (autoScaneAuthType === 'public') {
      setIdamOrIamCheckboxChecked(false);
      setFinalClickedAccessRequirements('allCanAccess');
      setIamOrOtherUserNameValue('');
      setIamOrOtherPasswordValue('');
      setMustLogOptionClicked(false);
      setAllCanAccessOptionClicked(true);
      setAuthTypeData((prevs) => prevs?.map((prev) => ({ ...prev, clicked: false })));
      authTypeDataRef.current = authTypeData.map((prev) => ({ ...prev, clicked: false }));
      allCanAccessOptionClickedRef.current = true;
      mustLogOptionClickedRef.current = false;
      idamOrIamCheckboxCheckedRef.current = false;
      iamOrOtherUserNameValueRef.current = '';
      iamOrOtherPasswordValueRef.current = '';
      finalClickedAccessRequirementsRef.current = 'allCanAccess';
      setAccessRequirementsValue('1');
      setAuthtypeValue(null);
    }
  };

  useEffect(() => {
    initHandler();
  }, [autoScaneAuthType]);

  useEffect(() => {
    setSelectedItem(authTypeData.find((item: any) => item.clicked === true));
  }, [authTypeData])

  const childContentItemClick = (item: { [x: string]: any }) => {
    setFinalAuthTypeUsed(item?.value);
    setAuthTypeData((prevs) => prevs.map((prev) => ({ ...prev, clicked: prev?.value === item?.value })));
    let param: any = {};
    switch (item?.value) {
      case 'idamOrIam':
        param = {
          imgSrc: LoggedOutSvg,
          h2Ele: 'Additional steps are needed.',
          descEle:
            "To scan applications using PwC IdAM or IAM's federated PwC identity service, additional steps are required to grant access to our scanner.",
        };
        setAuthtypeValue('0');
        break;
      case 'iamOrOther':
        param = {
          imgSrc: UpdatedPasswordUpdatedSvg,
          h2Ele: 'Access to your user credentials is needed.',
          descEle:
            'To scan applications using IAM or other authorization services, we need access to one of your admin usernames and passwords.',
        };
        setAuthtypeValue('1');
        break;
      case 'unsure':
        param = {
          imgSrc: ConsultingCyberSecuritySvg,
          h2Ele: 'Reach out to your development team.',
          descEle:
            'If unsure about the type of authentication used on your application, please reach out to your development team for clarification. ',
        };
        setAuthtypeValue('2');
        break;
      default:
        param = {};
    }
  };

  const getItemIcon = (value: string) => {
    if (value === 'idamOrIam') {
      return 'icon-shield-half-outline';
    }
    if (value === 'iamOrOther') {
      return 'icon-shield-outline';
    }
    if (value === 'unsure') {
      return 'icon-help-question-outline';
    }
  };

  const onCopyEvent = (str: string) => {
    setTimeout(() => {
      navigator.clipboard.writeText(str);
    }, 0);
    setMessageResultInfo(`copied`);
    announceMessage();
    return;
  }

  const onKeyDown = (event: React.KeyboardEvent, str: string) => {
    const key = event.key.toLowerCase();
    if (key === 'enter') {
      onCopyEvent(str);
    }
  };

  const getExpandPart = (item: any) => {
    let res: any;
    switch (item?.value) {
      case 'idamOrIam':
        res = (
          <div className="autoPage-content-stepperContent-accessOptionPart-child-content-item-expandPart-idamOrIam">
            <div className="autoPage-content-stepperContent-accessOptionPart-child-content-item-expandPart-idamOrIam-terms">
              {idamOrIamExpandData?.map((item, idx) => {
                let eles: any;
                if (item?.value === 'addAccounts') {
                  eles = (
                    <div className="autoPage-content-stepperContent-accessOptionPart-child-content-item-expandPart-idamOrIam-terms-single">
                      <span>{idx + 1}.</span>
                      <span dangerouslySetInnerHTML={{ __html: item.label }}></span>
                      <ul>
                        {item.links.map((link, index) => (
                          <li key={`${index}-${link}`}>
                            {/* <a href="#" target="_blank" rel="noopener noreferrer"> */}
                              {link}
                            {/* </a> */}
                            <Tooltip
                              trigger="hover"
                              position="top"
                              distance={4}
                              appendAfterTarget={false}
                              hideTooltipOnBlur={true}
                              content={"Copy account"}
                              className="tooltip-newIssue"
                            >
                            <span role="button" aria-label="Copy account" className="Appkit4-icon icon-link-outline" onClick={()=>onCopyEvent(link)} tabIndex={0} onKeyDown={(event)=>onKeyDown(event, link)}></span>
                            </Tooltip>
                          </li>
                        ))}
                      </ul>
                    </div>
                  );
                } else {
                  eles = (
                    <div className="autoPage-content-stepperContent-accessOptionPart-child-content-item-expandPart-idamOrIam-terms-single">
                      <span>{idx + 1}.</span>
                      <span>{item.label}</span>
                    </div>
                  );
                }
                return <div key={`${idx}-${item?.value}`}>{eles}</div>;
              })}
            </div>
            <div className={classNames("autoPage-content-stepperContent-accessOptionPart-child-content-item-expandPart-idamOrIam-checkbox", { "checked": idamOrIamCheckboxChecked })}>
              <Checkbox
                checked={idamOrIamCheckboxChecked}
                onChange={(checked: boolean, event: React.SyntheticEvent) => {
                  setIdamOrIamCheckboxChecked(checked);
                  event.stopPropagation();
                }}
              >
                Admin permissions have been granted to the required accounts.
              </Checkbox>
            </div>
          </div>
        );
        break;
      case 'iamOrOther':
        res = (
          <div className="autoPage-content-stepperContent-accessOptionPart-child-content-item-expandPart-iamOrOther">
            <div className="autoPage-content-stepperContent-accessOptionPart-child-content-item-expandPart-iamOrOther-desc">
              <span>Please provide one of your admin user credentials with full product access.</span>
            </div>
            <div className="autoPage-content-stepperContent-accessOptionPart-child-content-item-expandPart-iamOrOther-usrInfo">
              <Input
                value={iamOrOtherUserNameValue}
                onChange={(value: string, event?: React.ChangeEvent<HTMLInputElement>) => {
                  setIamOrOtherUserNameValue(value);
                }}
                type="text"
                title="Username"
                onClick={(event: React.MouseEvent) => event.stopPropagation()}
              />
              <Input
                value={iamOrOtherPasswordValue}
                type="password"
                onClick={(event: React.MouseEvent) => event.stopPropagation()}
                onChange={(value: string, event?: React.ChangeEvent<HTMLInputElement>) =>
                  setIamOrOtherPasswordValue(value)
                }
                title="Password"
              />
            </div>
          </div>
        );
        break;
      case 'unsure':
        res = (
          <div className="autoPage-content-stepperContent-accessOptionPart-child-content-item-expandPart-unsure">
            {/* <div>
              <span>1.</span>
              <span>Ask your development team the type of user authentication used in your product.</span>
            </div>
            <div>
              <span>2.</span>
              <span>Return to resume your process by selecting the correct authentication type.</span>
            </div> */}
            <div className="ap-page-section-desc ap-mt-spacing-3 notification-panel">
              <span className="Appkit4-icon icon-information-outline"></span>
              <div>Please confirm the authentication type with your development team. Once you have the information, return here to resume the process. </div>
            </div>
          </div>


        );
        break;
      default:
        res = <></>;
    }

    return res;
  };


  const getCredentialApiParam = () => {
    const id = curProductInfo?.id || (getProductInfoCookie()&&safelyParseJSON(getProductInfoCookie()!)?.id)||sessionStorage.getItem('productId');
    if (finalClickedAccessRequirements === 'allCanAccess') {
      return {
        id,
        authType: 'public',
      };
    }
    if (finalClickedAccessRequirements === 'mustLog') {
      if (finalAuthTypeUsed === 'idamOrIam') {
        return {
          id,
          authType: 'idam',
        };
      }
      if (finalAuthTypeUsed === 'iamOrOther') {
        return {
          id,
          authType: 'iam',
          authUser: iamOrOtherUserNameValue,
          authPass: iamOrOtherPasswordValue,
        };
      }
      if (finalAuthTypeUsed === 'unsure') {
        return {
          id,
          authType: 'unsure',
        };
      }
    }
  };

  const saveChangesHandler = () => {
    setSaveChangesLoading(true);
    updateAssetCredentialRef.current = assetService
      .updateAssetCredential(getCredentialApiParam())
      .pipe(take(1))
      .subscribe((res: any) => {
        if (res?.success) {
          setSaveChangesLoading(false);
          setVisibleFunc(false);
          setNeedRefreshFrom('editAccessRequirementsModal');
        }
      });
  };
  const getSaveChangesBtnDisabledStatus = useMemo(() => {
    if(allCanAccessOptionClicked === true && allCanAccessOptionClickedRef.current === allCanAccessOptionClicked) return true;
    if(isEqual(authTypeDataRef.current, authTypeData)
      &&allCanAccessOptionClickedRef.current === allCanAccessOptionClicked
      &&mustLogOptionClickedRef.current === mustLogOptionClicked
      &&finalClickedAccessRequirementsRef.current === finalClickedAccessRequirements
      &&finalAuthTypeUsedRef.current === finalAuthTypeUsed
      &&(finalAuthTypeUsedRef.current !== 'idamOrIam'||idamOrIamCheckboxCheckedRef.current === idamOrIamCheckboxChecked)
      &&(finalAuthTypeUsedRef.current !== 'iamOrOther'||(iamOrOtherUserNameValueRef.current === iamOrOtherUserNameValue
      &&iamOrOtherPasswordValueRef.current === iamOrOtherPasswordValue))) {
      return true;
    }
    if (finalClickedAccessRequirements === 'mustLog') {
      if (finalAuthTypeUsed === 'idamOrIam') {
        return idamOrIamCheckboxChecked ? false : true;
      } else if (finalAuthTypeUsed === 'iamOrOther') {
        if (iamOrOtherPasswordValue && iamOrOtherUserNameValue) return false;
        return true;
      } else {
        return true;
      }
    }

    return false;
  }, [authTypeData,allCanAccessOptionClicked,mustLogOptionClicked,idamOrIamCheckboxChecked,iamOrOtherUserNameValue,iamOrOtherPasswordValue]);

  const onClickAccordion = (index: number) => {
    if (index === 0) {
      setMustLogOptionClicked(true);
      setAllCanAccessOptionClicked(false);
      setAuthTypeData(authenticationTypesDataForSettingsPage);
      setFinalClickedAccessRequirements('mustLog');
      setAccessRequirementsValue('0');
      setMessageResultInfo('Users need to log in or sign up, selected');
      announceMessage();
    }
    if (index === 1) {
      setMustLogOptionClicked(false);
      setAllCanAccessOptionClicked(true);
      setAuthTypeData(authenticationTypesDataForSettingsPage);
      setFinalClickedAccessRequirements('allCanAccess');
      setAccessRequirementsValue('1');
      setSelectedItem(null);
      setAuthtypeValue(null);
      setFinalAuthTypeUsed('');
      setMessageResultInfo?.('No login is required, selected');
      announceMessage();
    }
    setChildAccoringActiveKeys(['']);
    setAccoringActiveKeys([index.toString()]);
  };

  const accordingData = [
    {
      value: 'userMustLogPart',
      header: (
        <div
          className={`autoPage-content-stepperContent-accessOptionPart-option ${mustLogOptionClicked ? 'clickedStyle' : ''}`}
          onClick={()=>onClickAccordion(0)} tabIndex={0}
          // onClick={() => {
          //   setMustLogOptionClicked(true);
          //   setAllCanAccessOptionClicked(false);
          //   setAuthTypeData(authenticationTypesData);
          //   setFinalClickedAccessRequirements('mustLog');
          // }}
          onKeyDown={(event: React.KeyboardEvent) => {
                      const key = event.key.toLowerCase();
                      if (key === 'enter') {
                        onClickAccordion(0);
                      }
                    }}
        >
          <div className="autoPage-content-stepperContent-accessOptionPart-option-icon">
          <Radio tabIndex={-1} checked={mustLogOptionClicked} value="0" aria-label="Users need to log in or sign up"></Radio>
          {/* {mustLogOptionClicked ? <span className="Appkit4-icon icon-radio-selected-fill" aria-label='open issue'></span> : <span className="Appkit4-icon icon-radio-deselected-fill"></span>} */}
            {/* <span className="Appkit4-icon icon-lockclosed-locked-outline autoPage-accessRequirements-icon"></span> */}
          </div>

          <div className="autoPage-content-stepperContent-accessOptionPart-option-texts">
            <div className="autoPage-content-stepperContent-accessOptionPart-option-texts-title">
              <div aria-hidden={true}>Users need to log in or sign up</div>
            </div>
            <div className="autoPage-content-stepperContent-accessOptionPart-option-texts-desc">
              <span aria-hidden={true}>
              Access is limited. Most PwC applications, except for public sites, fall into this category.
              </span>
            </div>
          </div>
        </div>
      ),
    },
    {
      value: 'anyoneCanAccessPart',
      header: (
        <div
          className={`autoPage-content-stepperContent-accessOptionPart-option allCanAccess ${allCanAccessOptionClicked ? 'clickedStyle' : ''}`}
          onClick={()=>onClickAccordion(1)} tabIndex={0}
          // onClick={() => {
          //   setMustLogOptionClicked(false);
          //   setAllCanAccessOptionClicked(true);
          //   setAuthTypeData(authenticationTypesData);
          //   setFinalClickedAccessRequirements('allCanAccess');
          // }}
          onKeyDown={(event: React.KeyboardEvent) => {
            const key = event.key.toLowerCase();
            if (key === 'enter') {
              onClickAccordion(1);
              // setMustLogOptionClicked(false);
              // setAllCanAccessOptionClicked(true);
              // setAuthTypeData(authenticationTypesDataForSettingsPage);
              // setFinalClickedAccessRequirements('allCanAccess');
              // setAccessRequirementsValue('1');
            }
          }}
        >
          <div className="autoPage-content-stepperContent-accessOptionPart-option-icon">
            {/* <span className="Appkit4-icon icon-lockopen-unlock-outline autoPage-accessRequirements-icon"></span> */}
            <Radio tabIndex={-1} checked={allCanAccessOptionClicked} value="1" aria-label="No login is required"></Radio>
            {/* {allCanAccessOptionClicked ? <span className="Appkit4-icon icon-radio-selected-fill" aria-label='open issue'></span> : <span className="Appkit4-icon icon-radio-deselected-fill"></span>} */}
          </div>
          <div className="autoPage-content-stepperContent-accessOptionPart-option-texts">
            <div className="autoPage-content-stepperContent-accessOptionPart-option-texts-title">
              <div aria-hidden={true}>No login is required</div>
            </div>
            <div className="autoPage-content-stepperContent-accessOptionPart-option-texts-desc">
              <span aria-hidden={true}>Access is open. Even external users can access without logging in.</span>
            </div>
          </div>
        </div>
      ),
    },
  ];
  const announceMessage = () => {
    setTimeout(() => {
      const messageElement = document.querySelector('.editAccessRequirementsMessage') as HTMLElement;
      const activeElement = document.activeElement as HTMLElement;
      if (messageElement) {
        messageElement.focus();
      }
      setTimeout(() => {
        setMessageResultInfo('');
        activeElement.focus();
      }, 2000);
    }, 100);
  }
  const [childAccoringActiveKeys, setChildAccoringActiveKeys] = React.useState<string[]>([]);
  const onChildAccordingClickAccordion = (index: number, event: React.MouseEvent|React.KeyboardEvent) => {
    setChildAccoringActiveKeys([index.toString()]);
    const item = authTypeData[index];
    childContentItemClick(item);
    setSelectedItem(item);
    setMessageResultInfo?.(`${item.title}, selected`);
    announceMessage();
  };
  const childAccordingHeaders = (item: any, idx: number) => {
    return (
      <div style={{ display: 'flex' }}>
        <div className="editAccessRequirementsModal-stepperContent-accessOptionPart-child-content-item-icon">
          {/* <span className={`Appkit4-icon ${getItemIcon(item?.value)} editAccessRequirementsModal-accRmts-icon`}></span> */}
          <Radio tabIndex={-1} checked={item?.clicked} value={idx.toString()} aria-label={item.title}></Radio>
        </div>
        <div className="editAccessRequirementsModal-stepperContent-accessOptionPart-child-content-item-texts">
          <div className="editAccessRequirementsModal-stepperContent-accessOptionPart-child-content-item-texts-title" aria-hidden={true}>
            {item.title}
          </div>
          <div className="editAccessRequirementsModal-stepperContent-accessOptionPart-child-content-item-texts-desc">
            <span aria-hidden={true}>{item.desc}</span>
          </div>
        </div>
      </div>
    );
  };

  const modalContent = {
    title: 'Access requirements',
    footer: (
      <>
        <div className="editAccessRequirementsModal-footer-rf">
          <span className='footer-required'>Required Fields</span>
        </div>
        <div>
          <Button
            kind="text"
            onClick={() => {
              initHandler();
              setVisibleFunc(false);
            }}
          >
            Cancel
          </Button>{' '}
          {saveChangesLoading ?
          <Button
            loading={saveChangesLoading}
            kind="primary"
            onClick={() => saveChangesHandler()}
          >
            Save changes
          </Button> :
          <Button
            kind="primary"
            onClick={() => saveChangesHandler()}
            disabled={getSaveChangesBtnDisabledStatus}
          >
            Save changes
          </Button>}
        </div>
      </>
    ),
    body: (
      <div className="editAccessRequirementsModal">
        <div className="editAccessRequirementsModal-stepperContent">
        <span id="mandatory-info" className="visually-hidden">Mandatory</span>
          <div className="editAccessRequirementsModal-stepperContent-accessOptionPart">
          {/* <div className="autoPage-content-stepperContent-accessOptionPart-sub-title">
            Select if log-in is required for the application to scan
          </div> */}
            {/* <Accordion
              multiple={false}
              onClick={onClickAccordion}
              activeKeys={accoringActiveKeys}
              showExpandIcon={false}
            >
              <AccordionItem templateHeader={() => accordingData[0].header} itemKey={'1'}>
                <div
                  className={`editAccessRequirementsModal-stepperContent-accessOptionPart-child ${mustLogOptionClicked ? 'show' : ''}`}
                >
                  <div className="editAccessRequirementsModal-stepperContent-accessOptionPart-child-title">
                    <h3>Authentication type used</h3>
                  </div>
                  <div className="editAccessRequirementsModal-stepperContent-accessOptionPart-child-content">
                    <Accordion
                      multiple={false}
                      onClick={(activeKeys: string[], event: React.MouseEvent<HTMLElement>) => {
                        onChildAccordingClickAccordion(activeKeys, event);
                      }}
                      activeKeys={childAccoringActiveKeys}
                      showExpandIcon={false}
                    >
                      {authTypeData?.map((item: any, idx: number) => (
                        <AccordionItem
                          key={`${idx}-${item?.value}`}
                          templateHeader={() => childAccordingHeaders(item)}
                          itemKey={`${idx}`}
                          className={`${item.clicked ? 'content-clicked' : ''}`}
                        >
                          <div style={{ display: 'flex', width: '100%' }}>
                            <div className="editAccessRequirementsModal-stepperContent-accessOptionPart-child-content-item-icon">
                              <span className={`Appkit4-icon  editAccessRequirementsModal-accRmts-icon`}></span>
                            </div>
                            <div
                              className={`editAccessRequirementsModal-stepperContent-accessOptionPart-child-content-item-expandPart ${item.clicked ? 'show' : ''}`}
                            >
                              <div className="editAccessRequirementsModal-stepperContent-accessOptionPart-child-content-item-expandPart-title">
                                <span>Before continuing</span>
                              </div>
                              {getExpandPart(item)}
                            </div>
                          </div>
                        </AccordionItem>
                      ))}
                    </Accordion>
                  </div>
                </div>
              </AccordionItem>
              <AccordionItem
                templateHeader={() => accordingData[1].header}
                itemKey={'2'}
                className="as-accordingItem2"
              ></AccordionItem>
            </Accordion> */}
            <fieldset>
            <legend className="autoPage-content-stepperContent-accessOptionPart-sub-title">
              <h3 id="login-required-label" aria-describedby="mandatory-info">Select if log-in is required for the application to scan</h3>
            </legend>
            <RadioGroup value={accessRequirementsValue} name="access requirements" aria-required="true">
            {accordingData[0].header}
            {accordingData[1].header}
            </RadioGroup>
            </fieldset>
            {mustLogOptionClicked&&<div
                className={`autoPage-content-stepperContent-accessOptionPart-child`}
              >
                {/* <div className="autoPage-content-stepperContent-accessOptionPart-child-title">
                  <h3>Authentication type used</h3>
                </div> */}
                
                <div className="autoPage-content-stepperContent-accessOptionPart-child-content">
                  {/* <Accordion
                    multiple={false}
                    onClick={(activeKeys: string[], event: React.MouseEvent<HTMLElement>) => {
                      onChildAccordingClickAccordion(activeKeys, event);
                    }}
                    activeKeys={childAccoringActiveKeys}
                    showExpandIcon={false}
                  >
                    
                  </Accordion> */}
                  <fieldset style={{ display: 'flex' }}>
                  <legend><h3 id="child-required-label" aria-describedby="mandatory-info">Authentication type used</h3></legend>
                  <RadioGroup value={authtypeValue} name="authentication type" aria-required="true">
                  {/* <ul className="ap-accordion-group-container"> */}
                  {authTypeData?.map((item: any, idx: number) => (
                    <div className={`ap-accordion ${item.clicked ? 'clickedStyle' : ''}`} onClick={(event) => onChildAccordingClickAccordion(idx, event)} tabIndex={0}
                    onKeyDown={(event: React.KeyboardEvent) => {
                                              const key = event.key.toLowerCase();
                                              if (key === 'enter') {
                                                onChildAccordingClickAccordion(idx, event);
                                              }
                                            }}>
                      {childAccordingHeaders(item, idx)}
                      </div>
                    ))}
                    {/* </ul> */}
                    </RadioGroup>
                    </fieldset>
                </div>
              </div>}
              {selectedItem&&<div style={{ display: 'flex', width: '100%' }}>
                          {/* <div className="autoPage-content-stepperContent-accessOptionPart-child-content-item-icon">
                            <span className={`Appkit4-icon autoPage-accessRequirements-icon`}></span>
                          </div> */}
                          <div
                            className={`autoPage-content-stepperContent-accessOptionPart-child-content-item-expandPart ${selectedItem?.clicked ? 'show' : ''}`}
                          >
                            <div className="autoPage-content-stepperContent-accessOptionPart-child-content-item-expandPart-title">
                              <span>{childAccoringActiveKeys?.[0]!=='2'? 'Next steps to grant scanner access':'Before continuing'}</span>
                            </div>
                  {getExpandPart(selectedItem)}
                  </div>
                  </div>}
          </div>
        </div>
      </div>
    ),
  };
 
  useEffect(() => {
    setUniqueKey(prevKey => prevKey + 1);
  }, [messageResultInfo]);
  return (
    <>
    <Drawer
      initialFocus={false}
      resizable={false}
      // <Button kind="secondary" onClick={() => setShowCreateIssueModal(false)}>Cancel</Button><Button onClick={() => setShowCreateIssueModal(false)}>Ok</Button>
      // footer={<>{modalContent.footer}</>}
      footer={modalContent.footer}
      visible={visible}
      placement="right"
      title="Access requirements"
      className="editAccessRequirementsModal"
      onClose={() => {initHandler();setVisibleFunc(false);}}
      mask={true}
      >
      {modalContent.body}
      {/* <div
      tabIndex={-1}
          aria-live="polite"
          aria-atomic="true"
          className="header-sr-only"
          key={uniqueKey}
        >
          {messageResultInfo}
        </div> */}
        <div
        className="editAccessRequirementsMessage"
        tabIndex={-1}
        style={{ position: 'absolute', left: '-9999px' }}
      >
        {messageResultInfo}
      </div>
  </Drawer>
 
  </>
  );
};

export default EditAccessRequirementsModal;

import React, { useMemo, useContext, useState, useEffect } from 'react';
import { Outlet, useSearchParams } from 'react-router-dom';
import SideBarNavigation from '@components/SiderBar';
import FooterPage from '@components/Footer';
import HeaderNew from '@components/Header';
import { EntryContext } from '@layout/Entry';
import './dashboardWrapper.scss';
import Home from '@components/Home';
import { useLocation } from 'react-router-dom';
import LoadingComponent from '@components/Loading';
import { Subscription, of, forkJoin } from 'rxjs';
import { take, switchMap, pluck, catchError, map } from 'rxjs/operators';
import { productService } from '@services';
import AutoMatedScanPage from '@layout/AutoMatedScan.page';
import { encryptData, setCookie, setProductInfoCookie } from '@utils/common';
import GettingStartedPage from '@layout/GettingStarted';
import HelpCenter from '@layout/HelpCenter';
import ManualReview from '@layout/ManualReview';
import LicensingPage from '@layout/Licensing';

const DashboardWrapper = (props: any) => {
  const location = useLocation();
  const { productList } = props;
  const [search] = useSearchParams();
  const {
    user,
    productName,
    collapsed,
    lastScanDate,
    setProductName,
    newestAssessmentId,
    setCurrentProductIdAndAssessmentId,
  } = useContext(EntryContext);
  const [pName, setPName] = useState('');
  // const localStorageUser = useMemo(() => {
  //   return decryptData(getCookie('user')||'');
  // }, []);

  const _appId = useMemo(() => {
    const id = search.get('appId');
    return id ? id : '';
  }, []);

  // const finalUser = useMemo(() => {
  //   if (user) return user;
  //   if (localStorageUser) return safelyParseJSON(localStorageUser);
  // }, [user, localStorageUser]);

  useEffect(() => {
    const appId = search.get('appId');
    let pNameSub: Subscription;
    if (appId) {
      pNameSub = productService
        .getProductInfo(appId)
        .pipe(take(1))
        .subscribe((res) => {
          if (res) {
            setPName(res?.name);
            setProductName(res?.name);
            sessionStorage.setItem('productId', String(res?.id));
            // setCookie('productInfo', JSON.stringify(res));
            setProductInfoCookie(res);
            setCurrentProductIdAndAssessmentId &&
              setCurrentProductIdAndAssessmentId({
                curProductId: String(res?.id),
                assessmentId: res?.lastestAssessment?.id,
              });
          }
        });
    }
    return () => {
      if (pNameSub) pNameSub.unsubscribe();
    };
  }, []);

  const getFooterPageStyle = () => {
    if (
      location.pathname.includes('/dwrapper/board') ||
      location.pathname.includes('/dwrapper/settings') ||
      location.pathname.includes('/dwrapper/issuedetail') ||
      location.pathname.includes('/autoMatedScan') ||
      !newestAssessmentId
    ) {
      return <FooterPage />;
    } else {
      return (
        <div className="col-9">
          <FooterPage />
        </div>
      );
    }
  };
  const content = useMemo(() => {
    if (location.pathname === '/') {
      return (
        <>
        {
          <div className={'sideBarNavigation home'}>
            <SideBarNavigation tabKey="0" appId={_appId} isForAll={true} />
          </div>
          }
          <div className={`outletcontainer`}>
            <div className={`ap-container home-page`}>
              <Home productList={productList} />
            </div>
          </div>
        </>
      );
    } else if (location.pathname.includes('/dwrapper')) {
      return (
        <>
          <div className={'sideBarNavigation'}>
            <SideBarNavigation tabKey="0" appId={_appId} />
          </div>
          <div className={collapsed ? 'outletcontainer-collapsed' : 'outletcontainer'}>
            <div className={`ap-container itemFooterContent`}>
              <Outlet />
              <div className={'not-first-use-tool-footer'}>
                <div className="row">
                  {/* <div className="col-9">
                    <FooterPage />
                  </div> */}
                  {getFooterPageStyle()}
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else if (location.pathname.includes('/autoMatedScan')) {
      return <AutoMatedScanPage />;
    } else if (location.pathname.includes('/manualIssue')) {
      return <ManualReview />;
    } else if (location.pathname.includes('/getting-started/tool-overview')) {
      return (
        <>
          <div className={'sideBarNavigation'}>
            <SideBarNavigation tabKey="0" appId={_appId} isForAll={true} />
          </div>
          <div className={`outletcontainer resource`}>
            <div className={`ap-container home getting-started`}>
                {location.pathname === '/getting-started/tool-overview'? <GettingStartedPage></GettingStartedPage> : <Outlet />}
                <div className={'not-first-use-tool-footer'}>
                  <div className="row">
                    {getFooterPageStyle()}
                  </div>
                </div>
            </div>
          </div>
        </>
      )
    }  else if (location.pathname.includes('/getting-started/licensing')) {
      return (
        <>
          <div className={'sideBarNavigation'}>
            <SideBarNavigation tabKey="0" appId={_appId} isForAll={true} />
          </div>
          <div className={`outletcontainer resource`}>
            <div className={`ap-container licensing`}>
                <LicensingPage/>
                <div className={'not-first-use-tool-footer help-center'}>
                  <div className="row">
                    {getFooterPageStyle()}
                  </div>
                </div>
            </div>
          </div>
        </>
      )
    } else if (location.pathname.includes('/getting-started')) {
      return (
        <>
        <div className={'sideBarNavigation'}>
          <SideBarNavigation tabKey="0" appId={_appId} isForAll={true} />
        </div>
        <div className={`outletcontainer resource`}>
          <div className={`ap-container licensing`}>
          <Outlet />
              <div className={'not-first-use-tool-footer getting-started'}>
                <div className="row">
                  {getFooterPageStyle()}
                </div>
              </div>
          </div>
        </div>
        </>
      );
    } else if (location.pathname.includes('/help-center')) {
      return (
        <>
          <div className={'sideBarNavigation'}>
            <SideBarNavigation tabKey="0" appId={_appId} isForAll={true} />
          </div>
          <div className={`outletcontainer resource`}>
            <div className={`ap-container home resource`}>
                {location.pathname === '/help-center'? <HelpCenter/> : <Outlet />}
                <div className={'not-first-use-tool-footer help-center'}>
                  <div className="row">
                    {getFooterPageStyle()}
                  </div>
                </div>
            </div>
          </div>
        </>
      )
    }
  }, [lastScanDate, collapsed, productList, location, newestAssessmentId]);

  const getShowHomePageClass = () => {
    return ['/', '/autoMatedScan', '/manualIssue'].includes(location.pathname);
  };

  return (
    <div className={`dashboard-wrapper-container ${getShowHomePageClass() ? 'homePage' : ''}`}>
      {/* {finalUser && <HeaderNew productName={location.pathname === '/' ? '' : pName} user={finalUser} />} */}
      <HeaderNew productName={location.pathname === '/' ? '' : pName} />
      {content}
    </div>
  );
};
export default DashboardWrapper;

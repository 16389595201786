import { Accordion, AccordionItem, Badge, Breadcrumb, BreadcrumbItem } from "@appkit4/react-components";
import { useCallback, useEffect, useState } from "react";
import './releaseNotes.scss';
import React from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import LoadingComponent from "@components/Loading";
import { getReleaseNotes } from "@services/resource.service";
import { Subscription } from "rxjs";

const ReleaseNotes = () => {
  const [activeKeys, setActiveKeys] = useState<string[]>([]);
  const [selectedNoteIndex, setSelectedNoteIndex] = useState<number>(0);
  const onTimelineClick = () => { };
  const navigate = useNavigate();
  const [releaseNotesData, setReleaseNotesData] = useState<any[]>([]);
  const [showLoading, setShowLoading] = useState(false);
  const onClickAccordion = useCallback((activeKeys: string[]) => {
    setActiveKeys(activeKeys);
    if(activeKeys.length > 0) setSelectedNoteIndex(parseInt(activeKeys?.[0]));
  }, [activeKeys])

  useEffect(() => {
    setShowLoading(true);
    let getReleaseNotesRef: any;
    getReleaseNotes().subscribe((res: any) => {
      setShowLoading(false);
      if (!res) return;
      setReleaseNotesData(res?.[0].contents);
    });
    return () => {
      getReleaseNotesRef?.unsubscribe();
    };
  }, [])

  const list = () => (
    <div className="ap-release-notes-container">
      <Accordion multiple={false} activeKeys={activeKeys} className="ap-release-notes-accordion" onClick={onClickAccordion}>
        {releaseNotesData?.map((item, i) => {
          return <AccordionItem itemKey={i.toString()} templateHeader={() => (
            <>
              {i === 0 && (
                <Badge styleClass="ap-release-notes-item-content-badge" value="Latest" type="primary" size="small" />
              )}
              <span className={classNames("ap-release-notes-item-content-title", {"latest": i === 0})} title={item.title}>{item.title}</span>
            </>
          )}>
            <div className="ap-accordion-extra ap-release-notes-timeline" onClick={onTimelineClick}>
              <div className={`ap-release-notes-timeline-date ${(selectedNoteIndex === i) ? 'date-active' : ''}`}>
                {item.date}
              </div>
              <div className={`ap-release-notes-timeline-tick ${(selectedNoteIndex === i) ? 'tick-active' : ''}`}></div>
              <div className="ap-release-notes-timeline-rail"></div>
            </div>

            <div className="ap-release-notes-item-content-detail">
              {item.detail.map((note: any, index: number) => (
                <React.Fragment key={index}>
                  {note.version && (
                    <div className="detail-version-wrapper">
                      <div className="detail-version">{note.version}</div>
                    </div>
                  )}
                  {note.content.map((noteContent: any, contentIndex: number) => (
                    <div className="detail-content" key={contentIndex}>
                      {noteContent.title && (
                        <div className="detail-content-title">{noteContent.title}</div>
                      )}
                      {noteContent.type === 'withoutSubTitle' && (
                        <ul className="detail-content-desc">
                          {noteContent.desc.map((desc: any, descIndex: number) => (
                            <li key={descIndex}>{desc.textContent}</li>
                          ))}
                        </ul>
                      )}
                      {noteContent.type === 'withInnerHtml' && (
                        <ul className="detail-content-desc">
                          {noteContent.desc.map((desc: any, descIndex: number) => (
                            <li key={descIndex} dangerouslySetInnerHTML={{ __html: desc.textContent }}></li>
                          ))}
                        </ul>
                      )}
                      {noteContent.type === 'withText' && (
                        noteContent.desc.map((descItem: any, descItemIndex: number) => (
                          <div className="detail-content-text" key={descItemIndex}>
                            {descItem.subTitle && (
                              <div className="detail-content-text-title">{descItem.subTitle}</div>
                            )}
                            {descItem.textContent && (
                              <div className="detail-content-text-content">{descItem.textContent}</div>
                            )}
                          </div>
                        ))
                      )}
                      {noteContent.type === 'withSubTitle' && (
                        <ul className="detail-content-desc">
                          {noteContent.desc.map((subItem: any, subItemIndex: number) => (
                            <li key={subItemIndex}>
                              {subItem.subTitle && (
                                <div className="detail-content-desc-sub-title">{subItem.subTitle}</div>
                              )}
                              {subItem.textContent && (
                                <span className="detail-content-desc-sub-desc">{subItem.textContent}</span>
                              )}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  ))}
                </React.Fragment>
              ))}
            </div>
          </AccordionItem>
        })}
      </Accordion>
    </div>
  );

  const goToPage = (url: string) => {
    navigate(`/${url}`);
  };

  return (
    <div className="ap-page ap-page-release-notes has-anchor">
      <div className="ap-page-content">
        <Breadcrumb>
          <BreadcrumbItem onClick={() => goToPage('help-center')}><span tabIndex={0}>Help center</span></BreadcrumbItem>
          <BreadcrumbItem path="/dwrapper/videos"><span>Release notes</span></BreadcrumbItem>
        </Breadcrumb>
        <div className="ap-component-wrapper release-notes">
          <div className="ap-component-name anchor-target">
            Release notes
          </div>
          <div className="ap-page-section-desc">
            Please click on subscribe in the left panel to get notified of all the updates and new releases.
          </div>
        </div>
      </div>
      {showLoading ? <LoadingComponent></LoadingComponent> : list()}
    </div>);
}

export default ReleaseNotes
import React, { useEffect, useRef } from 'react';
import { DropdownButton } from '@appkit4/react-components/dropdown';
import { Badge } from '@appkit4/react-components/badge';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { EntryContext } from '@layout/Entry';
import { Tooltip } from '@appkit4/react-components/tooltip';
import './index.scss';
import CommonModal from '@components/modals/CommonModal';
import PrivateLockSvg from '@assets/icons/private-lock.svg';
import AccessDeniedSvg from '@assets/icons/AccessDenied.svg';
import { encryptData, getNameAbbrev, setCookie, setProductInfoCookie } from '@utils/common';
import { Avatar } from '@appkit4/react-components/avatar';
import { productService } from '@services';
import { take } from 'rxjs/operators';
import { Button } from '@appkit4/react-components';

const ProjectDashboard = (props: any) => {
  const { product } = props;
  const navigate = useNavigate();
  const [desIconName, setDesIconName] = React.useState('icon-horizontal-more-outline'); // 需要根据数据变化
  const { setProductName, user, setLastScanDate } = React.useContext(EntryContext);
  const dropDownContent = [
    {
      value: 'goToSettings',
      label: 'Go to settings',
      iconName: '',
    },
  ];
  const [privateModalShow, setPrivateModalShow] = React.useState(false);
  const [assignedUsers, setAssignedUsers] = React.useState<any[]>([]);
  const getAssignedUser$ = React.useRef<any>(null);
  const dropdownBtnRef = React.useRef<HTMLDivElement>(null);
  const [productOwnerEmail, setProductOwnerEmail] = React.useState<any>({});

  useEffect(() => {
    if (dropdownBtnRef.current) {
      const target = dropdownBtnRef.current.querySelector('.custom-node')!;
      target?.setAttribute('aria-label', `${product.name} options`);
    }
  }, [dropdownBtnRef]);

  React.useEffect(() => {
    if (user) {
      setCookie('user', encryptData(user));
    }
  }, [user]);

  React.useEffect(() => {
    return () => {
      if (getAssignedUser$.current) getAssignedUser$.current.unsubscribe();
    };
  }, []);

  const getProjectDesc = () => {
    if (product.score) {
      const score = Number(product.score);
      if (score >= 0 && score < 85) {
        return {
          desc: 'Below minimum standards.',
          icon: <span className="Appkit4-icon icon-information-fill icon-danger"></span>,
        };
      } else if (score >= 85 && score < 100) {
        return {
          desc: 'Meets minimum standards.',
          icon: <span className="Appkit4-icon icon-success-fill icon-permittedWithCaution"></span>,
        };
      } else if (score === 100) {
        return {
          desc: 'Fully meets standards.',
          icon: <span className="Appkit4-icon icon-success-fill icon-success"></span>,
        };
      }
    } else {
      return { desc: 'Pending scan to see compliance level.', icon: null };
    }
  };

  const dropdownSelectHandler = (value: any, item: any, event: any) => {
    event.stopPropagation();
    if (!product.isAccessible) {
      setPrivateModalShow(true);
      return;
    }
    if (value === dropDownContent[0].value) {
      navigate(`/dwrapper/settings?appId=${product?.appId}`, {
        state: {
          productId: product?.id,
        },
      });
    }
  };

  const privateModalContent = React.useMemo(() => {
    return {
      title: '',
      body: (
        <div className="privateModal-bodyContainer" tabIndex={0}>
          <div className="privateModal-bodyContainer-imgArea">
            <img src={AccessDeniedSvg} alt={'Restricted access logo'} />
            <div>
              <h2>Restricted access.</h2>
              <span>Only team members added have access to {product?.name}.</span>
              <span>
                To request access to this product, please contact{' '}
                <a href={`mailto: ${productOwnerEmail}`} target="_blank" rel="noreferrer">
                  {productOwnerEmail}
                </a>
              </span>
            </div>
          </div>
          {/* <div className="privateModal-bodyContainer-assignedUserPart">
            {assignedUsers?.map((usr: any, idx: number) => (
              <div className={'privateModal-bodyContainer-assignedUserPart-usrContainer'}>
                <div className={'privateModal-bodyContainer-assignedUserPart-usrContainer-nickName'}>
                  <Avatar
                    tabIndex={0}
                    disabled={false}
                    role="button"
                    label={getNameAbbrev(usr?.firstName, usr?.lastName)}
                    ariaLabel={`User Profile,${user?.firstName} ${user?.lastName}`}
                  />
                </div>
                <div className={'privateModal-bodyContainer-assignedUserPart-usrContainer-nameEmail'}>
                  <span>{usr.formattedName}</span>
                  <span>{usr.id}</span>
                </div>
              </div>
            ))}
          </div> */}
        </div>
      ),
      footer: (
        <div className="privateModal-footer">
          <Button
            kind="secondary"
            onClick={() => {
              setPrivateModalShow(false);
            }}
          >
            Close
          </Button>
        </div>
      ),
    };
  }, [productOwnerEmail]);

  const openPrivateModal = (event: any) => {
    event?.stopPropagation();
    getAssignedUser$.current = productService
      .v2GetPrivateAssetMemberDetail(product?.appId)
      .pipe(take(1))
      .subscribe((res) => {
        setAssignedUsers(res?.data);

        // const ownerName = res?.owner;
        // const ownerInfo = res?.data.filter((m) => m.fullName === ownerName)[0];
        setProductOwnerEmail(res?.ownerName);

        setPrivateModalShow(true);
      });
  };

  const goToDashBoard = (event: any) => {
    // setCookie(
    //   'productInfo',
    //   JSON.stringify({
    //     productName: product.name,
    //     lastScanDate: product.lastScannedDate,
    //     productId: product.id,
    //   }),
    // );
    setProductInfoCookie({
      productName: product.name,
      lastScanDate: product.lastScannedDate,
      productId: product.id,
      name: product.name,
      id: product.id
    });
    openPrivateModal(event);
    if (!product.isAccessible) return;
    setProductName(product.name);
    setLastScanDate(product.lastScannedDate);
    navigate(`/dwrapper?appId=${product.appId}`);
  };

  const handleKeydownDashboard = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      goToDashBoard(e);
    }
  };

  return (
    <>
      <div
        className="projectDashboardContainer"
        onClick={goToDashBoard}
        tabIndex={0}
        onKeyDown={handleKeydownDashboard}
        role='link'
        aria-label={`${product.name}`}
      >
        <div className="projectDashboardContainer-nameThreeDotsArea">
          <div className="projectDashboardContainer-nameThreeDotsArea-name">
            <h2 title={product.name}>{product.name}</h2>
          </div>
          <div className="projectDashboardContainer-nameThreeDotsArea-dots">
            <DropdownButton
              ref={dropdownBtnRef}
              customTriggerNode={true}
              customTriggerClassName="custom-node"
              dropdownClassName="dpdStyle"
              data={dropDownContent!}
              onSelect={dropdownSelectHandler}
              prefixTemplate={(item) => <span className={`Appkit4-icon ${item.iconName}`}></span>}
            >
              <span className="Appkit4-icon icon-horizontal-more-outline" ></span>
            </DropdownButton>
          </div>
        </div>
        <div className="projectDashboardContainer-scoresArea">
          {product?.lastScannedDate && product?.score ? (
            <span className="projectDashboardContainer-scoresArea-score">
              {product.score ? (
                <>
                  {' '}
                  {/* {Number(product.score).toFixed(1)}% */}
                  {Number(product.score?.split('.')[0])}%
                  <span className="projectDashboardContainer-scoresArea-compliant">compliant</span>
                </>
              ) : (
                0
              )}
            </span>
          ) : (
            <Badge value="Not scanned" type={'info'} size="small" />
          )}
        </div>
        <div className="projectDashboardContainer-desArea">
          {getProjectDesc()?.icon}
          <span className={`projectDashboardContainer-desArea-desc ${getProjectDesc()?.icon ? 'margin' : ''}`}>
            {getProjectDesc()?.desc}
          </span>
        </div>
        <div className="projectDashboardContainer-timeArea">
          <span>
            {product?.lastScannedDate ? `Updated ${moment(product?.lastScannedDate).format('LL')}` : 'Not scanned'}.
          </span>
          {!product.isAccessible && (
            <Tooltip
              trigger="hover"
              position="top"
              distance={4}
              id="tooltipDesc"
              appendAfterTarget={false}
              hideTooltipOnBlur={true}
              content={'Private product'}
            >
              <span
                tabIndex={0}
                onClick={openPrivateModal}
                className="Appkit4-icon icon-lockclosed-locked-outline"
              ></span>
            </Tooltip>
          )}
        </div>
      </div>
      {privateModalShow && (
        <CommonModal
          visible={privateModalShow}
          modalContent={privateModalContent}
          setVisibleFunc={setPrivateModalShow}
          className="restrictedAccessModal"
          bodyStyle={{ maxHeight: '655px' }}
        />
      )}
    </>
  );
};
export default ProjectDashboard;

import React from 'react';
import { Avatar, Button, Footer, Header, HeaderOptionItem, ItemDataType, Navigation, NavigationItem, NavigationProps, HeaderProps } from '@appkit4/react-components';
import './index.scss';
import classNames from 'classnames';
export interface ErrorPageProps {
    errorImage?: string;
    onClickRedirect?:(event: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) => void,
    onClickLink?:(event: React.MouseEvent<HTMLElement>) => void,
    onSelect?: (value: string, item: ItemDataType) => void,
    pageTitle?: string;
    pageDescription?: string;
    linkText?: string;
    buttonText?: string;
    footerContent?: string;
    footerLinks?: ({
        name: string;
        href: string;
        target: string;
    } | {
        name: string;
        href: string;
        target?: undefined;
    })[];
    linkHref?: string;
    headerProps?:HeaderProps;
    navProps?:NavigationProps
}
const ErrorPage = React.forwardRef<HTMLElement, ErrorPageProps>((props: ErrorPageProps, ref) => {
    const [collapsedVal, setCollapsedVal] = React.useState(false);
    const {
        errorImage,
        onClickRedirect,
        pageTitle = 'Page not found',
        pageDescription = 'Experience our updated tool with enhanced features and a refreshed look.',
        buttonText = 'Explore now',
    } = props; 

    const onKeyDown = (event: React.KeyboardEvent) => {
        const key = event.key.toLowerCase();
        if (key === 'enter') {
            onClickRedirect?.(event as any);
        }
    }

    return (
        <div className="ap-pattern-error-page">
            <div className={classNames("ap-pattern-error-page-content", {
                'nav-collapse': collapsedVal
            })}>
                {/* <div className='page-logo'></div> */}
                <div className="error-page">
                    <div className="div-wrapper">
                        <img alt="" src={errorImage} className="error-image" />
                        <h1 className="ap-pattern-error-page-title">{pageTitle}</h1>
                        <span className="error-text">{pageDescription}</span>
                        <div className='view-directory'>
                            {/* <div className="view-directory-link">
                                <a href={linkHref} target="_blank" className="view-directory-icon" onClick={onClickLink}>
                                    <span className="Appkit4-icon icon-globe-outline"></span><span className="view-directory-text">{linkText}</span>
                                </a>
                            </div> */}
                            <Button onClick={onClickRedirect} onKeyDown={onKeyDown}>{buttonText}</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
})

export default ErrorPage
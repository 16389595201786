import React, { useEffect, useRef } from 'react';
import { Accordion, AccordionItem, HeaderProps, NavigationProps, Panel } from '@appkit4/react-components';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import './gettingStarted.scss';
import { addSideAnchorClass, formatUrl, removeHighlight } from '@utils/common';

export interface FAQProps {
    headerProps?: HeaderProps;
    navProps?: NavigationProps;
}

let anchorArray = [
    {
        value: 'Tool overview',
        selected: true,
        linkName: 'gettingstarted'
    },
    {
        value: "Understanding your application’s workspace",
        linkName: "understanding"
    }
];
const GettingStartedPage = React.forwardRef<HTMLElement, FAQProps>((props: FAQProps, ref) => {
    const navigate = useNavigate();
    const anchorRef = useRef(null);
    const faqRef = useRef(null);
    const clickRef = useRef<boolean>(false);
    const itemListRef = React.useRef<{ name: string, top: number, target: HTMLElement }[]>([]);
    const [anchorList, setAnchorList] = React.useState(anchorArray);
    const pageContent = React.useRef<HTMLDivElement>(null);
    const [collapsedVal, setCollapsedVal] = React.useState(false);
    const [showAnchors, setShowAnchors] = React.useState(false);
    const [anchorLeft, setAnchorLeft] = React.useState(0);
    const anchorEvent = (fragment?: string) => {
        clickRef.current = true;
        let str = (fragment ? formatUrl(decodeURI(fragment)) : '');
        let indexStr = anchorArray.findIndex(item => formatUrl(item.linkName) === str);
        if (str === '') indexStr = 0;
        removeHighlight(anchorRef, indexStr);
        const elementTop = fragment ? (document.getElementById(str)?.offsetTop || 0) : 0;
        setTimeout(function () {
            window.scrollTo({
                top: elementTop - 64
            });
        }, 0);
        setAnchorList(anchorArray.map((item, index) => {
            if (formatUrl(item.linkName?.toLowerCase()) === str?.toLowerCase())
                return { ...item, selected: true };
            else if (!fragment && index === indexStr)
                return { ...item, selected: true };
            else return { ...item, selected: false };
        })); 
        // if ((!fragment && indexStr === 0) ||
        //     (fragment && indexStr === -1)) (anchorRef.current! as HTMLElement)?.querySelectorAll(`[id^=anchor-]`)[0].classList.add("selected");
        if (indexStr === -1)
            setTimeout(function () {
                window.scrollTo({
                    top: - 64
                });
            }, 0);
        const element = fragment&&(pageContent.current as HTMLElement)?.querySelector(`#${fragment}`);
        if (fragment === 'gettingstarted') {
            setTimeout(() => {
                if (element) {
                    element.scrollIntoView({ block: 'end' });
                }
            }, 0);
        } else {
            setTimeout(() => {
                if (element) {
                    element.scrollIntoView({ block: 'center' });
                }
            }, 0);
        }
        setTimeout(() => {
            clickRef.current = false;
            if(!(anchorRef.current! as HTMLElement)?.querySelector(`#anchor-${fragment}`)?.classList.contains("selected"))
            (anchorRef.current! as HTMLElement)?.querySelector(`#anchor-${fragment}`)?.classList.add("selected");
        }, 300);
    }

    const hashChangeEvent = () => {
        anchorEvent(window.location.hash.slice(1));
    }

    const setAnchorsEvent = () => {
        const pageContentElement = (pageContent.current as HTMLElement);
        setShowAnchors(true);
        setAnchorLeft(pageContentElement.getBoundingClientRect().left + pageContentElement.getBoundingClientRect().width + 32);
    }

    const isInViewport = (element: HTMLElement) => {
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
        );
    }

    const scrollEvent = () => {
        if(clickRef.current) return;
        setTimeout(() => {
            const itemList = itemListRef.current;
            let scrollTop = Math.max(document.documentElement.scrollTop, document.body.scrollTop) + 28;
            for (let index = 0; index < itemList.length; ++index) {
                const trigger = (anchorRef.current! as HTMLElement).querySelector(`[id=anchor-${itemList[index]?.name.toLowerCase()}]`) as HTMLElement;
                if (itemList[index + 1] && scrollTop >= itemList[index].top - 64 && scrollTop <= itemList[index + 1].top - 64) {
                    addSideAnchorClass(anchorRef, trigger);
                    if (!itemList[index + 2] && (window.innerHeight + Math.round(window.scrollY)) >= document.body.offsetHeight && isInViewport(itemList[index + 1].target))
                        addSideAnchorClass(anchorRef, (anchorRef.current! as HTMLElement).querySelector(`[id=anchor-${itemList[index + 1]?.name.toLowerCase()}]`) as HTMLElement);
                    break;
                } else if (index === itemList.length - 1 && scrollTop >= itemList[index].top - 64) {
                    addSideAnchorClass(anchorRef, trigger);
                    break;
                }
            }
        }, 300);
    }

    React.useEffect(() => {
        setTimeout(() => {
            if (pageContent.current) {
                removeHighlight(anchorRef);
                setAnchorsEvent();
            }
            hashChangeEvent();
            updateItemListRef();
        }, 100);
        window.addEventListener('hashchange', hashChangeEvent);
        window.addEventListener('resize', setAnchorsEvent);
        window.addEventListener('scroll', scrollEvent);
        window.addEventListener('keyup', keyupEvent);
        return () => {
            window.removeEventListener('hashchange', hashChangeEvent);
            window.removeEventListener('scroll', scrollEvent);
            window.removeEventListener('resize', setAnchorsEvent);
        }
    }, [])

    const keyupEvent = (event: KeyboardEvent) => {
        if (event.keyCode === 9) {
            const accordionList = (faqRef.current! as HTMLElement)?.querySelectorAll('.ap-accordion-toggle');
            if(!accordionList) return;
            if (Array.from(accordionList).indexOf(document.activeElement!) === accordionList.length - 1)
                addSideAnchorClass(anchorRef, (anchorRef.current! as HTMLElement).querySelector(`[id=anchor-${itemListRef.current[itemListRef.current.length - 1]?.name.toLowerCase()}]`) as HTMLElement);
            if (document.activeElement?.textContent === 'Tool overview')
                addSideAnchorClass(anchorRef, (anchorRef.current! as HTMLElement).querySelector(`[id=anchor-${itemListRef.current[0]?.name.toLowerCase()}]`) as HTMLElement);
        }
    }

    const updateItemListRef = () => {
        setTimeout(() => {
            let intersectItem = (pageContent.current! as HTMLElement)?.querySelectorAll('.anchor-target');
            let tempList = [];
            for (let i = 0; i < intersectItem.length; ++i) {
                const item = (intersectItem[i] as HTMLElement);
                tempList.push({ name: item.id, top: item.offsetTop, target: item });
            }
            itemListRef.current = tempList;
            setAnchorsEvent();
        }, 300);
    }

    const onAnchorClick = (value?: string) => {
        anchorEvent(value);
    }

    const onKeyDown = (event: React.KeyboardEvent<HTMLElement>, value?: string) => {
        const { key } = event;
        if (key === 'Enter') {
            onAnchorClick?.(value)
        }
    }

    const handleKeyDownAutomated = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            navigate("/getting-started/automated-assessment");
        }
    }

    const handleKeyDownManual = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            navigate("/getting-started/manual-review");
        }
    }

    return (
        <div className="ap-page-get-started ap-mt-spacing-6" tabIndex={-1}>
            <div className="pattern-content faq-content">
                <div className={classNames("ap-page has-anchor", {
                    'faq-page-collapse': collapsedVal
                })}>
                    <div className="ap-page-content has-anchor" id="pageContent" ref={pageContent}>
                        <div className="ap-component-wrapper no-top-bottom">
                            <div className="ap-component-name anchor-target" id="gettingstarted">
                            Tool overview
                            </div>
                            <div className="ap-page-section-desc ap-mt-spacing-5">
                                The Accessibility Checker is a comprehensive tool that simplifies and enhances your application's accessibility assessment. It combines two crucial components of a thorough assessment: an automated assessment and a self-guided manual review. With the Accessibility Checker, you have a one-stop shop to enhance the accessibility of your application.
                                <br />
                                <br />
                                To begin the accessibility assessment for your application, the first step is to add your application.
                            </div>
                            <Panel bordered={true} className='ap-mt-spacing-4'>
                                <div className="ap-panel-content step-content">
                                    <ul className='no-space-list'>
                                        <li>1. Click on the + <span className="text-bold">Add application</span> button.</li>
                                        <li>2. Enter the name of the application or website.</li>
                                        <li>3. Set the privacy setting.</li>
                                        <li>4. Add additional team members.</li>
                                        <li>5. Click on <span className="text-bold">Add</span> to finish.</li>
                                    </ul>
                                </div>
                            </Panel>
                        </div>
                        <div className="ap-component-wrapper no-top-bottom">
                            <div className="ap-page-section-desc ap-mt-spacing-4 desc-3">
                                After adding your application, you are all set. Start with the automated assessment of your application and complement your assessment with a manual review.
                            </div>
                            <div>
                                <div className="ap-page-card-container">
                                    <a className="ap-page-card clickable"
                                    onKeyDown={handleKeyDownAutomated}
                                        href="/getting-started/automated-assessment">
                                        <div className="card-top">
                                            <div>
                                                <div className="card-top-left">Automated assessment</div>
                                                <div className="card-bottom">Learn how to set up your first automated scan.</div>
                                            </div>
                                            <div className="icon-wrapper">
                                                <span className="Appkit4-icon icon-analysis-outline"></span>
                                            </div>
                                        </div>
                                    </a>
                                    <a className="ap-page-card clickable"
                                    onKeyDown={handleKeyDownManual}
                                    href="/getting-started/manual-review">
                                        <div className="card-top">
                                            <div>
                                                <div className="card-top-left">Manual review</div>
                                                <div className="card-bottom">Learn how to conduct your manual review.</div>
                                            </div>
                                            <div className="icon-wrapper">
                                                <span className="Appkit4-icon icon-survey-checkmark-outline"></span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="ap-component-name ap-mt-spacing-7 anchor-target" id="understanding">Understanding your application’s workspace</div>
                                <div className={classNames('ap-pattern-faq-list ap-mt-spacing-6')}>
                                    <div className="ap-pattern-faq-section">
                                        <div className="ap-pattern-faq-content getting-started">
                                            <Accordion multiple={false}>
                                                <AccordionItem title="Dashboard" itemKey={"0"}>
                                                    <span className="ap-accordion-text">
                                                        The dashboard displays your progress towards conforming to WCAG 2.2 Level AA guidelines. Conformance requires a combination of automated and manual reviews.
                                                    </span>

                                                    <div className="ap-accordion-text panel-content ap-mt-spacing-4">
                                                        <div>WCAG 2.2 conformance</div>
                                                        <div>The Accessibility Checker starts with an automated score for the provided URL. It scans a portion of WCAG 2.2 guidelines, while the rest is reviewed manually by your team. Both automated and manual assessments are necessary for an accurate assessment.</div>

                                                        <li><span>Minimum requirement (85%): </span>Your application must achieve a score of 85% and high priority issues, categorized as "Critical" and "Serious," must be prioritized before launch. </li>
                                                        <li><span>End goal (100%): </span>The end goal represents full conformance. Remaining issues should be resolved, and continuous monitoring is recommended. </li>

                                                    </div>

                                                    <div className="ap-accordion-text panel-content ap-mt-spacing-4">
                                                        <div>High priority issues</div>
                                                        <div>These include Critical and Serious issues. It is crucial to resolve these issues and ensure a WCAG conformance level of at least 85% before releasing the application.</div>
                                                    </div>

                                                    <div className="ap-accordion-text panel-content ap-mt-spacing-4">
                                                        <div>Low priority issues</div>
                                                        <div>These include Moderate and Minor issues. Fixing these issues will move you closer to the end goal of 100% conformance.</div>
                                                    </div>

                                                    <div className="ap-accordion-text panel-content ap-mt-spacing-4">
                                                        <div>Recommended actions.</div>
                                                        <div>Provides tips for your accessibility journey. </div>
                                                    </div>
                                                </AccordionItem>
                                            </Accordion>
                                        </div>
                                    </div>
                                    <div className="ap-pattern-faq-section">
                                        <div className="ap-pattern-faq-content getting-started">
                                            <Accordion multiple={false}>
                                                <AccordionItem title="Issues" itemKey={"0"}>
                                                    <span className="ap-accordion-text">
                                                        Accessibility issues are categorized under automated scans and manual reviews. Users can search for issues or filter them by severity, effort level, and categories.
                                                    </span>
                                                    <span className="ap-accordion-text ap-mt-spacing-6">
                                                        Each issue listed provides general information, a code snippet, the number of pages where the issue appears, and suggested fixes. Comment and image upload features are also available.
                                                    </span>
                                                </AccordionItem>
                                            </Accordion>
                                        </div>
                                    </div>

                                    <div className="ap-pattern-faq-section">
                                        <div className="ap-pattern-faq-content getting-started">
                                            <Accordion multiple={false}>
                                                <AccordionItem title="Settings" itemKey={"0"}>
                                                    <span className="ap-accordion-text">
                                                        The settings section allows you to add team members by role, manage access and notifications, define access requirements, and add or remove URLs for assessment.
                                                    </span>
                                                </AccordionItem>
                                            </Accordion>
                                        </div>
                                    </div>

                                    <div className="ap-pattern-faq-section">
                                        <div className="ap-pattern-faq-content getting-started">
                                            <Accordion multiple={false}>
                                                <AccordionItem title="Search assesments" itemKey={"0"}>
                                                    <span className="ap-accordion-text">
                                                        On the welcome page, you can find a list of previously viewed and created assessments. Reviewers can browse the list or use the search field to find assessments by name.
                                                    </span>
                                                </AccordionItem>
                                            </Accordion>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {showAnchors && <nav ref={anchorRef} className={classNames('ap-pattern-page-anchor', "anchor-list")} style={{ left: `${anchorLeft}px` }}>
                        <ul>
                            {
                                anchorList?.map(
                                    (anchor: any, index: number) => {
                                        const hrefValue = window.location.href.indexOf("#") > -1 ? window.location.href.substring(0, window.location.href.indexOf("#")) : window.location.href;
                                        const hrefProp = true ? { href: encodeURI(hrefValue + '#' + formatUrl(anchor.linkName!)) } : null;
                                        return <li key={index} onClick={(event) => onAnchorClick?.(anchor.linkName)} onKeyDown={(event) => onKeyDown(event, anchor.linkName)}>
                                            <a tabIndex={0} role="link" aria-current={anchor.selected ? 'page' : undefined} {...hrefProp} id={'anchor-' + formatUrl(anchor.linkName!)}
                                                className={classNames("anchor-trigger", { 'selected': anchor.selected })}>
                                                {anchor.externalLink && <span className={anchor.externalLinkIcon || "Appkit4-icon icon-open-in-new-outline"}></span>}
                                                {anchor.value}
                                            </a>
                                        </li>
                                    }
                                )
                            }
                        </ul>
                    </nav>}
                </div>
            </div>
        </div>
    )
})

export default GettingStartedPage
import { BrowserRouter } from 'react-router-dom';
import EntryRouters from './Routers';
import '@styles/index.scss';
import React, { useEffect, useMemo, useState, createContext } from 'react';
import { AuthUserType, UserType } from '@models';
import { userService } from '@services/user.service';
import { authService } from '@services/auth.service';
import { catchError, concatMap, take } from 'rxjs/operators';
import { of, Subscription } from 'rxjs';
import { getCookie, setCookie, uuid } from '@utils';
import LoadingComponent from '@components/Loading';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { createBrowserHistory } from 'history';
import { ItemType } from '@components/Issues/types';
const { ThemeContainer } = require('appkit-react');

interface EntryContextType {
  authUser: AuthUserType | null;
  user: UserType | null;
  isLogin: boolean;
  currentUserEmailNotifiaction: boolean;
  sessionId: string;
  isCRSuccessed: boolean;
  isViewedCryptoReward: boolean;
  productName: string;
  collapsed?: boolean;
  lastScanDate: string;
  isDarkMode?: boolean;

  setIsLogin?: any;
  setAuthUser?: any;
  setUser?: any;
  setPageStautsMsg?: any;
  setCurrentUserEmailNotifiaction?: any;
  setKeyboardIndicationShow?: any;
  setIsViewedCryptoReward?: any;
  setProductName?: any;
  setCollapsed?: any;
  setLastScanDate?: any;
  setIsDarkMode?: React.Dispatch<React.SetStateAction<boolean>>;
  newestAssessmentId?: string;
  setNewestAssessmentId?: React.Dispatch<React.SetStateAction<string>>;
  latestAssessmentResult?: any;
  setLatestAssessmentResult?: React.Dispatch<React.SetStateAction<any>>;
  closeScanModalStatus?: boolean;
  setCloseScanModalStatus?: React.Dispatch<React.SetStateAction<boolean>>;
  closeScanModalMethod?: boolean;
  setCloseScanModalMethod?: React.Dispatch<React.SetStateAction<any>>;
  scanModalFrom?: any;
  setScanModalFrom?: React.Dispatch<React.SetStateAction<any>>;

  reScanBtnDisabled?: boolean;
  setReScanBtnDisabled?: React.Dispatch<React.SetStateAction<boolean>>;

  currentProductIdAndAssessmentId?: any;
  setCurrentProductIdAndAssessmentId?: React.Dispatch<React.SetStateAction<any>>;
  saveIssueClicked?: boolean;
  setSaveIssueClicked?: React.Dispatch<React.SetStateAction<boolean>>;
  showVerificationCode?: boolean;
  setShowVerificationCode?: React.Dispatch<React.SetStateAction<boolean>>;
  assetScanningErrorTime?: any;
  setAssetScanningErrorTime?: React.Dispatch<React.SetStateAction<any>>;
  originTData?: any;
  setOriginTData?: React.Dispatch<React.SetStateAction<any>>;
  verificationResultType?: string;
  setVerificationResultType?: React.Dispatch<React.SetStateAction<any>>;
  verificationCodeInfo?: string;
  setVerificationCodeInfo?: React.Dispatch<React.SetStateAction<any>>;
  messageResult?: string;
  setMessageResult?: React.Dispatch<React.SetStateAction<any>>;
  narrowScreenSelectedItems: any;
  setNarrowScreenSelectedItems: React.Dispatch<React.SetStateAction<any>>;
}

const EntryContextValue: EntryContextType = {
  authUser: null,
  user: null,
  isLogin: false,
  currentUserEmailNotifiaction: false,
  sessionId: '',
  isCRSuccessed: false,
  isViewedCryptoReward: false,
  productName: '',
  collapsed: false,
  lastScanDate: '',
  isDarkMode: false,
  newestAssessmentId: '',
  latestAssessmentResult: null,
  closeScanModalMethod: false,
  closeScanModalStatus: false,
  scanModalFrom: '',
  reScanBtnDisabled: false,
  currentProductIdAndAssessmentId: '',
  saveIssueClicked: false,
  narrowScreenSelectedItems: null,
  setNarrowScreenSelectedItems: () => {}
};

export const EntryContext = createContext(EntryContextValue);

function Entry() {
  const [checkingAuthToken, setCheckingAuthToken] = useState<boolean>(true);
  const [authUser, setAuthUser] = useState<AuthUserType | null>(null);
  const [user, setUser] = useState<UserType | null>(null);
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [isLogin, setIsLogin] = useState<boolean>(false);
  const [pageStautsMsg, setPageStautsMsg] = useState('');
  const [productName, setProductName] = useState('');
  const [isCRSuccessed, setIsCRSuccessed] = useState<boolean>(false);
  const [isViewedCryptoReward, setIsViewedCryptoReward] = useState<boolean>(false);
  const [currentUserEmailNotifiaction, setCurrentUserEmailNotifiaction] = useState<boolean>(false);
  const [lastScanDate, setLastScanDate] = useState('');
  const [sessionId] = useState(uuid(16));
  const [isDarkMode, setIsDarkMode] = useState(false);
  // const { pathname, search } = useLocation();
  const [newestAssessmentId, setNewestAssessmentId] = useState('');
  const [latestAssessmentResult, setLatestAssessmentResult] = useState<any>();
  const [closeScanModalMethod, setCloseScanModalMethod] = useState<any>(false);
  const [closeScanModalStatus, setCloseScanModalStatus] = useState<any>(false);
  const [scanModalFrom, setScanModalFrom] = useState<any>();
  const [reScanBtnDisabled, setReScanBtnDisabled] = useState<boolean>(false);
  const [currentProductIdAndAssessmentId, setCurrentProductIdAndAssessmentId] = useState<any>('');
  const [saveIssueClicked, setSaveIssueClicked] = useState<boolean>(false);
  const [showVerificationCode, setShowVerificationCode] = useState(false);
  const [assetScanningErrorTime, setAssetScanningErrorTime] = useState<any>(undefined);
  const [originTData, setOriginTData] = useState<any>(null);
  const [verificationResultType, setVerificationResultType] = useState<string>('');
  const [verificationCodeInfo, setVerificationCodeInfo] = useState<any>('');
  const [messageResult, setMessageResult] = useState<string>('');
  const initialItems = sessionStorage.getItem('issueFilterConfig');
  const parsedItems: ItemType[] = initialItems ? JSON.parse(initialItems) : [];
  const [narrowScreenSelectedItems, setNarrowScreenSelectedItems] = useState<ItemType[]>(parsedItems);
  const authUserCloudEmail = useMemo(() => {
    return authUser?.cloudEmail || authUser?.preferredMail || '';
  }, [authUser]);
  // useEffect(() => {
  //   localStorage.setItem('scanModalFrom', '');
  // }, []);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const codeValue = urlParams.get('code');
    if(getCookie('checkSuccess')==='true') {
      setCheckingAuthToken(false);
      if (window.location.href?.includes('/v2')) {
        const history = createBrowserHistory();
        history.push(window.location.href.replace('/v2', ''));
        window.location.reload();
      }
      return;
    }
    if(codeValue && codeValue !== '')
    sessionStorage.setItem('code', codeValue||'');
    setCheckingAuthToken(true);
    if (!isLogin) {
      if (!getCookie('idToken')&&!sessionStorage.getItem('code')) {
        const env = process.env.REACT_APP_env;
        const loginUrl =  sessionStorage.getItem('loginType') === 'internal' ? (process.env.REACT_APP_pwc_login_url || '') : (process.env.REACT_APP_pwc_external_login_url || ''); // sessionStorage.getItem('loginType') === 'internal'? 
        const appUrl = process.env.REACT_APP_app_url || 'https://pisc-accessibility-dev.azurewebsites.net';
        // const state = encodeURIComponent(`${appUrl}${pathname}${search}`);
        if (loginUrl && env !== 'local') {
          sessionStorage.setItem('currentUrl', window.location.href.replace('/v2', ''));
          window.location.href = `${loginUrl}` || '';
        } else if (env === 'local') {
          return;
        }
      }
    }
    
    if(process.env.REACT_APP_env !== 'local' && sessionStorage.getItem('code') && sessionStorage.getItem('code') !== '')
      authService.login(sessionStorage.getItem('code')||'').pipe(take(1))
      .subscribe((res) => {
        // const setCookieHeader = res.headers['set-cookie'];
        // setCookie('idToken', res?.idToken);
        setCookie('checkSuccess', 'true');
        sessionStorage.setItem('code', '');
        setCheckingAuthToken(false);
        const currentUrl = sessionStorage.getItem('currentUrl') || '';
        // window.location.href = currentUrl !== ''? currentUrl : (`${process.env.REACT_APP_app_redirect_url}`||'');//'https://dev.accessibility.pwcinternal.com/';
        const history = createBrowserHistory();
        if (currentUrl !== '') {
          history.push(currentUrl);
          window.location.reload();
          sessionStorage.removeItem('currentUrl');
        }
        else window.location.href = `${process.env.REACT_APP_app_redirect_url}` || '';//'https://dev.accessibility.pwcinternal.com/';
      }, (error) => {
        // Handle error
        const localStorageIdToken = getCookie('idToken') || '';
        // if (error.status === 400) {
        //   const env = process.env.REACT_APP_env;
        //   const loginUrl = process.env.REACT_APP_pwc_login_url || '';
        //   const appUrl = process.env.REACT_APP_app_url || 'https://pisc-accessibility-dev.azurewebsites.net';
        //   // const state = encodeURIComponent(`${appUrl}${pathname}${search}`);
        //   const state = encodeURIComponent(`${appUrl}`);
        //   if (loginUrl && env !== 'local') {
        //     window.location.href = `${loginUrl}&state=${state}` || '';
        //   } else if (env === 'local') {
        //     return;
        //   }
        // }
        if (error.status === 400 && !!localStorageIdToken) {
          setCookie('idToken', '');
          setCookie('azureAuthUser', '');
          setCookie('checkSuccess', '');
          sessionStorage.setItem('code', '');
          return;
        }
      })
  }, []);

  useEffect(() => {
    // let checkAuthTokenSub: any;
    let getUserSub: Subscription | null = null;
    if (authUserCloudEmail && !user) {
      getUserSub = userService
        .getUser(authUserCloudEmail)
        .pipe(
          take(1),
          concatMap((res) => {
            if (res && res.status === 1 && !!res.data) {
              return of({
                data: res.data,
                status: 1,
                message: 'ok',
              });
            } else if (authUser) {
              const { given_name, family_name, name, cloudEmail, preferredMail } = authUser;
              return userService.createUser({
                firstName: given_name,
                lastName: family_name,
                name: name,
                userEmail: cloudEmail||preferredMail,
              });
            }
            return of(null);
          }),
          concatMap((res) => {
            if (res) {
              const _userEmail = res?.data?.id;
              if (_userEmail) {
                userService.sendAccessCryptoRewards(_userEmail).subscribe((_res) => {
                  if (_res.code === 'BID_SUCCESS') {
                    setIsCRSuccessed(true);
                  }
                });
              }
            }
            return of(res);
          }),
          catchError((err) => {
            if (authUser) {
              const { given_name, family_name, name, cloudEmail, preferredMail } = authUser;
              return userService.createUser({
                firstName: given_name,
                lastName: family_name,
                name: name,
                userEmail: cloudEmail||preferredMail,
              });
            }
            return of(null);
          }),
        )
        .subscribe((res) => {
          if (res) {
            setUser(res.data);
            setIsLogin(true);
            // checkAuthTokenSub = userService
            //   .checkAuthToken()
            //   .subscribe(res => {});
          }
        });
    }
    return () => {
      if (getUserSub) {
        getUserSub.unsubscribe();
      }
      // checkAuthTokenSub?.unsubscribe();
    };
  }, [authUserCloudEmail, authUser, user]);

  useEffect(() => {
    const logout = () => authService.logout();
    window.addEventListener('offline', logout);
    return () => {
      window.removeEventListener('offline', logout);
    };
  }, []);

  return (
    <BrowserRouter>
      <ThemeContainer>
        {checkingAuthToken ? (
          <LoadingComponent />
        ) : (
          <EntryContext.Provider
            value={{
              authUser,
              user,
              isLogin,
              currentUserEmailNotifiaction,
              sessionId,
              lastScanDate,
              collapsed,
              setIsLogin,
              setAuthUser,
              setUser,
              setPageStautsMsg,
              setCurrentUserEmailNotifiaction,
              isCRSuccessed,
              isViewedCryptoReward,
              setIsViewedCryptoReward,
              productName,
              setProductName,
              setCollapsed,
              setLastScanDate,
              isDarkMode,
              setIsDarkMode,
              newestAssessmentId,
              setNewestAssessmentId,
              latestAssessmentResult,
              setLatestAssessmentResult,
              closeScanModalMethod,
              setCloseScanModalMethod,
              closeScanModalStatus,
              setCloseScanModalStatus,
              scanModalFrom,
              setScanModalFrom,
              reScanBtnDisabled,
              setReScanBtnDisabled,
              currentProductIdAndAssessmentId,
              setCurrentProductIdAndAssessmentId,
              saveIssueClicked,
              setSaveIssueClicked,
              showVerificationCode,
              setShowVerificationCode,
              assetScanningErrorTime, 
              setAssetScanningErrorTime,
              originTData,
              setOriginTData,
              verificationResultType,
              setVerificationResultType,
              verificationCodeInfo,
              setVerificationCodeInfo,
              messageResult,
              setMessageResult,
              narrowScreenSelectedItems,
              setNarrowScreenSelectedItems
            }}
          >
            <main>
              <EntryRouters></EntryRouters>
            </main>
          </EntryContext.Provider>
        )}
      </ThemeContainer>
    </BrowserRouter>
  );
}

export default Entry;

